import styled from 'styled-components';

export const NewsWrapper = styled.div`
    margin-bottom: 75px;
    h2 {
        font-weight: 700;
        font-size: 36px;
        line-height: 150%;
        color: #1E2226;
    }
    .cards {
        margin-top: 30px;
        .card {
            width: 100%;
            img {
                height: 200px;
            }
            p {
                margin: 20px 0;
                font-weight: 300;
                font-size: 14px;
                line-height: 130%;
                color: #A3AAB0;
            }
            h4 {
                font-weight: 700;
                font-size: 18px;
                line-height: 130%;
            }
        }
    }
    .swiper-button-next,
    .swiper-button-prev, 
    .swiper-pagination-bullet {
        display: none;
    }

    @media only screen and (max-width: 500px) {
        margin-top: 30px;
    }
`