import styled from 'styled-components';
import { BLURBG } from '../../assets/images_path';

export const OurHistoryWrapper = styled.div`
    
    .gradient-background {
        background: url(${BLURBG}) no-repeat center/cover, linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));        
        height: auto;
        color: white;
        .gradient {
            position: relative;
            h2 {
                top: 130px;
                left: 0;
                height: 620px;
                position: absolute;
                font-weight: 700;
                font-size: 102px;
                line-height: 74.9%;
                writing-mode: vertical-rl;
                transform: rotate(-180deg);
            }
            .texts {
                h3 {
                    font-weight: 700;
                    font-size: 50px;
                    line-height: 105.9%;
                }
                .text-div {
                    p {
                        margin: 5px 0;
                        width: 500px;
                    }
                }
            }
            
        }        
    }

    @media only screen and (max-width: 1200px) {
        .gradient-background {
            height: 620px;
            .gradient {
                .text-hidden_div {
                    display: none;
                }
                .texts {
                    h3 {
                        font-size: 25px;
                        padding: 20px 0;
                    }
                    .text-div {
                        width: 700px;
                        p {
                            margin: 15px 0;
                            width: 100%;
                            font-size: 16px;
                        }
                    }
                }
                
            }
        }        
    }

    @media only screen and (max-width: 1000px) {
        .gradient-background {
            height: 620px;
            .gradient {
                .text-hidden_div {
                    display: none;
                }
                .texts {
                    h3 {
                        font-size: 25px;
                        padding: 20px 0;
                    }
                    .text-div {
                        width: 550px;
                        p {
                            margin: 15px 0;
                            width: 100%;
                            font-size: 16px;
                        }
                    }
                }
                
            }
        }        
    }

    @media only screen and (max-width: 850px) {
        .gradient-background {
            height: 620px;
            .gradient {
                .text-hidden_div {
                    display: none;
                }
                .texts {
                    h3 {
                        font-size: 25px;
                        padding: 20px 0;
                    }
                    .text-div {
                        width: 400px;
                        p {
                            margin: 15px 0;
                            width: 100%;
                            font-size: 16px;
                        }
                    }
                }
                
            }
        }        
    }

    @media only screen and (max-width: 700px) {
        .gradient-background {
            height: auto;
            .gradient {
                h2, .text-hidden_div {
                    display: none;
                }
                .texts {
                    h3 {
                        font-size: 25px;
                        padding: 20px 0;
                    }
                    .text-div {
                        width: 100%;
                        p {
                            margin: 15px 0;
                            width: 100%;
                            font-size: 16px;
                        }
                    }
                }
                
            }
        }        
    }
`