import axios from "axios";
import React, { useState } from "react";
import { DOCK, OK } from "../../assets/images_path";
import { FormWrapper } from "./FormWrapper";

const Form = ({ modal, setstate }) => {
  const [boxs, setboxs] = useState(false);

  // const sendData = (event) => {
  //   event.preventDefault();

  //   let data = {
  //     name: event.target.ism.value,
  //     surname: event.target.familya.value,
  //     email: event.target.email.value,
  //     contact: event.target.tel.value,
  //   };
  //   if (event.target.checkbox.checked) {
  //     axios
  //       .post("https://api.synergy-university.uz/myapp/message/", data)
  //       .then((res) => {
  //         setboxs(true);
  //         setTimeout(() => {
  //           setstate(false);
  //         }, 2500);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }
  // };
  return (
    <FormWrapper>
      {modal ? (
        <div className="plus">
          <p
            onClick={() => {
              setstate(false);
              setboxs(false);
            }}
          >
            +
          </p>
        </div>
      ) : (
        ""
      )}
      {boxs ? (
        <div className={modal ? "boxs" : "boxs boxs2"}>
          <img src={OK} alt="" />
          <h3>
            Ваша заявка успешно <br /> отправлена
          </h3>
          <p>
            Менеджер свяжется с вами в <br /> ближайшее время.
          </p>
          <a
            href={DOCK}
            target="blank"
            onClick={() => {
              setboxs(false);
            }}
            className="button"
          >
            скачать каталог
          </a>
        </div>
      ) : (
        ""
      )}
      <form
        action="https://formSubmit.co/ralisher@gmail.com"
        method="post"
        encType="text/plan"
      >
        <input type="text" name="Имя" placeholder="Имя" required />
        <input type="text" name="Фамилия" placeholder="Фамилия" required />
        <input
          type="number"
          name="tel"
          placeholder="+998 (00) 000-00-00"
          required
        />
        <input type="email" name="email" placeholder="E-mail" required />
        <div className="flex items-center">
          <input
            type="checkbox"
            name="Я_согласен_с_условиями_обработки_персональных_данных."
            className="input"
            id="in"
            required
          />
          <label htmlFor="in">
            Я согласен с условиями обработки персональных данных.
          </label>
        </div>
        {modal ? (
          <button className="button" type="submit">
            Получить консультацию
          </button>
        ) : (
          <div className="grid_box">
            <button className="button" type="submit">
              Получить консультацию
            </button>
            <a
              href={DOCK}
              target="blank"
              onClick={() => {
                setboxs(false);
              }}
              className="button"
            >
              скачать каталог
            </a>
          </div>
        )}
      </form>
    </FormWrapper>
  );
};

export default Form;
