import styled from "styled-components";

export const CatalogWrapper = styled.div`
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  padding: 15px 30px;
  position: relative;
  height: 400px;
  width: 100%;
  .over {
    .plus {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      p {
        font-family: "Raleway", sans-serif;
        font-weight: 100;
        cursor: pointer;
        display: inline-block;
        font-size: 30px;
        transform: scale(3) rotate(45deg);
      }
    }
    .catalog-top {
      margin-top: -25px;
      h2 {
        font-weight: 500;
        font-size: 20px;
        line-height: 140%;
        color: #000000;
        width: 230px;
        text-align: center;
      }
    }
    .catalog-list {
      border: 1px solid #000000;
      border-radius: 5px;
      height: 250px;
      overflow-y: scroll;
      margin-top: 50px;
      .facult {
        background: #ffffff;
        border: 1px solid #000000;
        border-radius: 3px;
        padding: 5px 30px;
        position: absolute;
        top: 94px;
        left: 50%;
        transform: translate(-50%, 0);
        z-index: 2;
        p {
          font-size: 20px;
          line-height: 140%;
        }
      }
      .list-div {
        margin: 20px 0;
        height: 70px;
        position: relative;
        h3 {
          width: 230px;
          text-align: center;
        }
        h4 {
          width: 260px;
        }
      }
    }
  }

  @media only screen and (max-width: 1050px) {
    height: 100%;
    .over {
      .catalog-top {
        h2 {
          font-size: 18px;
        }
      }
      .catalog-list {
        .facult {
          padding: 5px 10px;
          p {
            font-size: 16px;
          }
        }
        .list-div {
          h3,
          h4 {
            font-size: 16px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 800px) {
    height: max-content;
    min-height: 450px;
    max-height: 700px;
    overflow-x: auto;
    .over {
      width: 600px;
      .catalog-top {
        h2 {
          font-size: 16px;
        }
      }
      .catalog-list {
        height: 300px;
        .facult {
          padding: 5px 10px;
          position: fixed;
          top: 193px;
          p {
            font-size: 14px;
          }
        }
        .list-div {
          h3,
          h4 {
            font-size: 15px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 500px) {
    height: max-content;
    min-height: 450px;
    max-height: 700px;
    .over {
      .catalog-top {
        margin-top: 30px;
      }
      .plus {
        position: fixed;
        top: 190px;
        right: 34px;
      }
      .catalog-list {
        .facult {
          width: max-content;
          top: 282px;
        }
      }
    }
  }
`;
