import React, { useContext } from "react";
import { FOLLOW } from "../../assets/images_path";
import { Context } from "../../context";
import { FollowWrapper } from "./FollowWrapper";

const Follow = () => {

  const { icons } = useContext(Context)

  return (
    <FollowWrapper>
      <div className="container mx-auto">
        <div className="blur_div flex justify-between items-center">
          <div className="share_div">
            <h2>Поделитесь в социальных сетях</h2>
            <div className="icons_div flex justify-between">
              {icons?.map((el) => (
                <div className="icon" key={el.id}>
                  <a href={el.link} target="blank">
                    <img src={el.icon} alt="" />
                  </a>
                </div>
              ))}
            </div>
          </div>
          <img className="image" src={FOLLOW} alt="" />
        </div>
      </div>
    </FollowWrapper>
  );
};

export default Follow;
