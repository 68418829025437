import styled from 'styled-components';

export const FollowWrapper = styled.div`
    height: 500px;
    .blur_div {
        border: 1px solid #E5E5E5;
        box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.05);
        border-radius: 12px;
        margin-top: 100px;
        .share_div {
            padding: 100px;
            h2 {
                width: auto;
                font-size: 40px;
                line-height: 130%;
                color: #000000;
            }
            .icons_div {
                margin-top: 20px;
                column-gap: 50px;                
            }
        }
    }

    @media only screen and (max-width: 700px) {
        display: none;
    }

    @media only screen and (max-width: 1000px) {
        height: auto;
        .blur_div {
            margin: 40px 0;
            /* flex-direction: column-reverse; */
            .share_div {
                padding: 30px;
                h2 {
                    width: auto;
                    font-size: 24px;
                }
                .icons_div {
                    column-gap: 15px;
                    img {
                        width: 24px;
                        height: 24px;
                    
                    }
                }
            }
            .image {
                width: 300px;
                height: auto;
            }
        }
    }
`