import React, { useContext } from "react";
import { LOGO } from "../../assets/images_path";
import { Context } from "../../context";
import { FooterWrapper } from "./FooterWrapper";

const Footer = () => {

  const { icons } = useContext(Context)

  return (
    <FooterWrapper>
      <footer>
        <div className="container mx-auto">
          <div className="footer-over">
            <div className="footer-logo flex justify-between">
              <div className="links">
                <img className="logo" src={LOGO} alt="logo" />
              </div>
              <div className="footer_icons text-white">
                <h3>Подпишись на наши обновления</h3>
                <div className="icons flex justify-between">
                  {icons?.map((el) => (
                    <div className="icon" key={el.id}>
                      <a href={el.link} target="blank">
                        <img src={el.icon} alt="" />
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="footer-menu flex justify-between">
              <div className="contact-adress_div flex flex-col">
                <div className="contact_div flex flex-col">
                  <h3>Наши контакты</h3>
                  <a href="tel: +998(71)231-71-71">
                    <p>+998 (71) 231-71-71</p>
                  </a>
                  <a href="tel: +998(90)928-60-87">
                    <p>+998 (90) 928-60-87</p>
                  </a>
                </div>
                <div className="address_div mt-12 flex flex-col">
                  <h3>Наша адреса</h3>
                  <p>100015 Ташкент <br /> ул. Нукусская, д.85/1, офис 321. </p>
                </div>
              </div>
              <div className="abiturient_div flex flex-col">
                <h3>Абитуриентам</h3>
                <a href="#about">
                  <p>Программа обучения</p>
                </a>
              </div>
              <div className="about_div flex flex-col">
                <h3>Об университете</h3>
                <a href="#our-history">
                  <p>История</p>
                </a>
                <a href="#section3">
                  <p>Новости</p>
                </a>
                <a href="#license">
                  <p>Лицензии и аккредетации</p>
                </a>
              </div>
              <div className="bakalavr_div flex flex-col">
                <a href="#">
                  <h3>Бакалавриат</h3>
                </a>
                <a href="#">
                  <h3>Магистратура</h3>
                </a>
                <a href="#">
                  <h3>Обучение в Дубае</h3>
                </a>
              </div>
            </div>
            <div className="line"></div>
            <div className="footer-year flex justify-start">
              <p>Все права защищены Университет Synergy 2022.</p>
            </div>
          </div>
        </div>
      </footer>
    </FooterWrapper>
  );
};

export default Footer;
