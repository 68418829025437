import React from "react";
import { CatalogWrapper } from "./CatalogWrapper";

const Catalog = ({ facultet, name, setstate, state }) => {
  return (
    <CatalogWrapper>
      <div className="boxs">
        <div className="over">
          <div className="plus">
            <p onClick={() => setstate(false)}> +</p>
          </div>
          <div className="catalog-top flex justify-between ">
            <h2>Уровень образования</h2>
            <h2>Специальность</h2>
            <h2>Факультет</h2>
          </div>
          <div className="catalog-list">
            <div className="facult">
              <p>{name}</p>
            </div>
            {facultet?.map((v) => {
              if (v.level == state || "Все" == state) {
                return (
                  <div className="list-div flex text-center items-center justify-between">
                    <h3>{v.level}</h3>
                    <h3>{v.speciality}</h3>
                    <h4>{v.program}</h4>
                  </div>
                );
              }
            })}
          </div>
        </div>
      </div>
    </CatalogWrapper>
  );
};

export default Catalog;
