import styled from "styled-components";
import { ICON2 } from "../../assets/icon_path";

export const BlueFormWrapper = styled.div`
  background-color: #667cc5;
  padding: 120px 0 80px 0;

  background-image: url(${ICON2});
  background-repeat: no-repeat;
  background-position: 5% 95%;

  .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 60px;
    .boxs {
      h2 {
        font-family: "Raleway", sans-serif;
        font-weight: 700;
        font-size: 36px;
        line-height: 130%;
        color: #ffffff;
      }
      p {
        margin-top: 40px;
        font-family: "Raleway", sans-serif;
        font-weight: 400;
        font-size: 20px;
        line-height: 140%;
        color: #ffffff;
      }
    }
  }
  @media only screen and (max-width: 1050px) {
    padding: 80px 0 60px 0;
    .container {
      display: grid;
      grid-template-columns: 1fr;
      gap: 40px;
      .boxs {
        h2 {
          font-size: 30px;
        }
        p {
          margin-top: 32px;
          font-size: 18px;
        }
      }
    }
  }
  @media only screen and (max-width: 500px) {
    padding: 50px 0 40px 0;
    .container {
      display: grid;
      grid-template-columns: 1fr;
      gap: 20px;
      .boxs {
        h2 {
          font-size: 20px;
        }
        p {
          margin-top: 24px;
          font-size: 14px;
        }
      }
    }
  }
`;
