import styled from "styled-components";

export const FacultetWrapper = styled.div`
  margin-top: 100px;
  h1 {
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    font-size: 36px;
    line-height: 130%;
    color: #000000;
    text-align: center;
  }
  .button {
    margin: 30px 0 60px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      font-family: sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: #000000;
      margin-right: 40px;
      transition: all 0.4s linear;
      &:last-child {
        margin-right: 0;
      }
      position: relative;
      &::after {
        position: absolute;
        content: "";
        height: 2px;
        left: 0;
        bottom: -3px;
        width: 15px;
        background-color: #000000;
        transition: all 0.4s linear;
      }
    }
    .active {
      transition: all 0.4s linear;
      color: #fe334a;
      &::after {
        position: absolute;
        content: "";
        height: 2px;
        left: 0;
        bottom: -3px;
        width: 100%;
        background-color: #fe334a;
        transition: all 0.4s linear;
      }
    }
  }

  .swiper {
    width: 100%;
    height: 100%;
    padding-bottom: 80px;
    .swiper-slide {
      background: #ffffff;
      border: 1px solid #e5e5e5;
      box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.05);
      border-radius: 12px;
      overflow: hidden;
      height: auto;
      img {
        width: 100%;
        height: 250px;
      }
      .box {
        padding: 10px 30px 30px 30px;
        p {
          font-family: "Raleway", sans-serif;
          font-weight: 700;
          font-size: 17px;
          line-height: 140%;
          color: #000000;
        }
        h3 {
          margin: 20px 0 40px 0;
          font-family: "Raleway", sans-serif;
          font-weight: 600;
          font-size: 20px;
          line-height: 130%;
          color: #000000;
        }
        button {
          border: 1px solid #3d8be4;
          border-radius: 3px;
          padding: 8px 30px;
          margin: auto;
          display: block;
          font-family: "Raleway", sans-serif;
          font-weight: 500;
          font-size: 16px;
          line-height: 150%;
          color: #00249e;
        }
      }
    }
    .swiper-button-next,
    .swiper-button-prev {
      top: 94%;
      color: #fe334a;
      z-index: 99;
      border-radius: 50%;
      border: 1px solid #fe334a;
      width: 50px;
      height: 50px;
    }
    .swiper-button-next:after,
    .swiper-button-prev:after {
      font-size: 24px;
    }
    .swiper-button-next {
      right: 40%;
    }
    .swiper-button-prev {
      left: 40%;
    }
    .swiper-pagination-bullet-active {
      background: #fe334a;
    }
    .swiper-pagination-bullet {
      width: 12px;
      height: 12px;
      display: none;
    }
  }

  @media only screen and (max-width: 900px) {
    margin-top: 50px;
    h1 {
      font-size: 32px;
    }
    .button {
      margin: 30px 0 50px 0;
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      gap: 20px;
      row-gap: 20px;
      button {
        font-size: 20px;
        margin: auto;
        &:last-child {
          margin: auto;
        }
      }
    }

    .swiper {
      padding-bottom: 0px;
      .swiper-slide {
        border-radius: 12px;
        img {
          width: 100%;
          height: 200px;
        }
        .box {
          padding: 10px 25px 25px 25px;
          p {
            font-size: 17px;
          }
          h3 {
            margin: 15px 0 30px 0;
            font-size: 20px;
          }
          button {
            padding: 8px 30px;
            font-size: 16px;
          }
        }
      }
      .swiper-button-next,
      .swiper-button-prev {
        display: none;
      }

      .swiper-pagination-bullet {
        display: none;
      }
    }
  }
  @media only screen and (max-width: 500px) {
    margin-top: 35px;
    h1 {
      font-size: 25px;
    }
    .button {
      margin: 20px 0 35px 0;
      gap: 10px;
      row-gap: 20px;
      button {
        font-size: 14px;
        margin: auto;
      }
    }

    .swiper {
      .swiper-slide {
        border-radius: 12px;
        img {
          width: 100%;
          height: 130px;
        }
        .box {
          padding: 8px 15px 15px 15px;
          p {
            font-size: 15px;
          }
          h3 {
            margin: 10px 0 20px 0;
            font-size: 18px;
          }
          button {
            padding: 6px 30px;
            font-size: 14px;
          }
        }
      }
      .swiper-button-next,
      .swiper-button-prev {
        display: none;
      }

      .swiper-pagination-bullet {
        display: none;
      }
    }
  }
`;
