import React, { useState, createContext } from "react";
import { INSTAGRAM, FACEBOOK, TELEGRAM, WHATSAPP } from "./assets/icon_path";
import { LICENSE_PIC, LICENSE_PDF, CERTIFICATE_PIC, CERTIFICATE_PDF, ACCREDITATION, ACCREDITATION_PDF, BANK, FINANCE, PSYCHOLOGY, PEDAGOGIKA, MANAGEMENT, IT, LIGVISTIKA, DIZAYN, IT_PROFESSIONS, DUBAE, DUBAE2, DUBAE3, DUBAE4 } from "./assets/images_path";

const Context = createContext();

const ContextProvider = ({ children }) => {

    const icons = [
        {
            "id": 1,
            "icon": INSTAGRAM,
            "link": "https://www.instagram.com/synergy_tashkent/"
        },
        {
            "id": 2,
            "icon": FACEBOOK,
            "link": "https://www.facebook.com/synergytashkent"
        },
        {
            "id": 3,
            "icon": TELEGRAM,
            "link": "https://t.me/synergy_tashkent"
        },
        {
            "id": 4,
            "icon": WHATSAPP,
            "link": "https://wa.me/message/72O7C4KOR3IXF1?src=qr"
        }
    ]

    const [slider, setslider] = useState([
        {
            id: "1",
            img: BANK,
            name: "ФАКУЛЬТЕТ БАНКОВСКОГО ДЕЛА",
            facultets: [
                {
                    id: 1,
                    level: "бакалавриат",
                    speciality: "Экономика",
                    program: "Банковское дело"
                },
                {
                    id: 2,
                    level: "магистратура",
                    speciality: "Финансы и кредит",
                    program: "Банки"
                }
            ],
        },
        {
            id: "2",
            img: FINANCE,
            name: "ФАКУЛЬТЕТ ЭКОНОМИКИ",
            facultets: [
                {
                    id: 1,
                    level: "бакалавриат",
                    speciality: "Экономика",
                    program: "Бухгалтерский учет, анализ и аудит"
                },
                {
                    id: 2,
                    level: "бакалавриат",
                    speciality: "Экономика",
                    program: "Налоги и налогообложение"
                },
                {
                    id: 3,
                    level: "бакалавриат",
                    speciality: "Экономика",
                    program: "Финансы и кредит"
                },
                {
                    id: 4,
                    level: "магистратура",
                    speciality: "Экономика",
                    program: "Бухгалтерский учет, анализ и аудит"
                },
                {
                    id: 5,
                    level: "магистратура",
                    speciality: "Финансы и кредит",
                    program: "Финансы"
                }
            ],
        },
        {
            id: "3",
            img: PSYCHOLOGY,
            name: "ФАКУЛЬТЕТ ПСИХОЛОГИИ",
            facultets: [
                {
                    id: 1,
                    level: "бакалавриат",
                    speciality: "Психология",
                    program: "Социальная психология"
                },
                {
                    id: 2,
                    level: "магистратура",
                    speciality: "Психология",
                    program: "Организационная психология"
                }
            ],
        },
        {
            id: "4",
            img: PEDAGOGIKA,
            name: "ФАКУЛЬТЕТ ПЕДАГОГИКИ",
            facultets: [
                {
                    id: 1,
                    level: "бакалавриат",
                    speciality: "Психолого-педагогическое образование",
                    program: "Психология и педагогика дошкольного образования"
                },
                {
                    id: 2,
                    level: "бакалавриат",
                    speciality: "Педагогическое образование",
                    program: "Психология  и педагогика начального образования"
                },
                {
                    id: 3,
                    level: "магистратура",
                    speciality: "Психолого-педагогическое образование",
                    program: "Психология образования"
                }
            ],
        },
        {
            id: "5",
            img: MANAGEMENT,
            name: "ФАКУЛЬТЕТ УПРАВЛЕНИЯ",
            facultets: [
                {
                    id: 1,
                    level: "бакалавриат",
                    speciality: "Менеджмент",
                    program: "Маркетинг"
                },
                {
                    id: 2,
                    level: "бакалавриат",
                    speciality: "Менеджмент",
                    program: "Управление проектами"
                },
                {
                    id: 3,
                    level: "бакалавриат",
                    speciality: "Менеджмент",
                    program: "Финансовый менеджмент"
                },
                {
                    id: 4,
                    level: "бакалавриат",
                    speciality: "Менеджмент",
                    program: "Логистика"
                },
                {
                    id: 5,
                    level: "бакалавриат",
                    speciality: "Менеджмент",
                    program: "Менеджмент в образовании"
                },
                {
                    id: 6,
                    level: "бакалавриат",
                    speciality: "Менеджмент",
                    program: "Менеджмент наукоемких производств нефтегазохимического комплекса"
                },
                {
                    id: 7,
                    level: "бакалавриат",
                    speciality: "Менеджмент",
                    program: "Менеджмент в машиностроении"
                },
                {
                    id: 8,
                    level: "бакалавриат",
                    speciality: "Менеджмент",
                    program: "Менеджмент в здравоохранении"
                },
                {
                    id: 9,
                    level: "бакалавриат",
                    speciality: "Менеджмент",
                    program: "Менеджмент в строительстве"
                },
                {
                    id: 10,
                    level: "бакалавриат",
                    speciality: "Менеджмент",
                    program: "Менеджмент в энергетике"
                },
                {
                    id: 11,
                    level: "бакалавриат",
                    speciality: "Государственное и муниципальное управление",
                    program: "Государственная и муниципальная служба"
                },
                {
                    id: 12,
                    level: "бакалавриат",
                    speciality: "Управление персоналом",
                    program: "Кадровый менеджмент"
                },
                {
                    id: 13,
                    level: "магистратура",
                    speciality: "Управление персоналом",
                    program: "HR-менеджмент"
                },
                {
                    id: 14,
                    level: "магистратура",
                    speciality: "Менеджмент",
                    program: "Менеджмент в образовании"
                },
                {
                    id: 15,
                    level: "магистратура",
                    speciality: "Менеджмент",
                    program: "Проектный менеджмент"
                },
                {
                    id: 16,
                    level: "магистратура",
                    speciality: "Менеджмент",
                    program: "Стратегический менеджмент"
                },
                {
                    id: 17,
                    level: "магистратура",
                    speciality: "Государственное и муниципальное управление",
                    program: "Система государственного и муниципального управления"
                },
            ],
        },
        {
            id: "6",
            img: IT,
            name: "ФАКУЛЬТЕТ ИНФОРМАЦИОННЫХ ТЕХНОЛОГИЙ",
            facultets: [
                {
                    id: 1,
                    level: "бакалавриат",
                    speciality: "Бизнес-информатика",
                    program: "ИТ-менеджмент в бизнесе"
                },
                {
                    id: 2,
                    level: "бакалавриат",
                    speciality: "Прикладная информатика",
                    program: "Прикладная информатика в экономике"
                },
                {
                    id: 3,
                    level: "бакалавриат",
                    speciality: "Информационные системы и технологии",
                    program: "Разработка, сопровождение и обеспечение безопасности информационных систем"
                },
                {
                    id: 4,
                    level: "магистратура",
                    speciality: "Прикладная информатика",
                    program: "Программное обеспечение, Интернет и облачные технологии."
                },
                {
                    id: 5,
                    level: "магистратура",
                    speciality: "Прикладная информатика",
                    program: "Управление информационными технологиями в организации"
                }
            ],
        },
        {
            id: "7",
            img: LIGVISTIKA,
            name: "ФАКУЛЬТЕТ ЛИНГВИСТИКИ",
            facultets: [
                {
                    id: 1,
                    level: "бакалавриат",
                    speciality: "Лингвистика",
                    program: "Теория и практика перевода (английский и испанский языки)"
                },
                {
                    id: 2,
                    level: "бакалавриат",
                    speciality: "Лингвистика",
                    program: "Теория и практика перевода (английский и немецкий языки)"
                },
                {
                    id: 3,
                    level: "магистратура",
                    speciality: "Лингвистика",
                    program: "Межъязыковые и межкультурные коммуникации"
                }
            ],
        },
        {
            id: "8",
            img: DIZAYN,
            name: "ФАКУЛЬТЕТ ДИЗАЙНА И РЕКЛАМЫ",
            facultets: [
                {
                    id: 1,
                    level: "бакалавриат",
                    speciality: "Дизайн",
                    program: "Графический дизайн и виртуальная дополненная реальность"
                },
                {
                    id: 2,
                    level: "бакалавриат",
                    speciality: "Дизайн",
                    program: "Архитектурный дизайн"
                },
                {
                    id: 3,
                    level: "магистратура",
                    speciality: "Реклама и связи с общественностью",
                    program: "Управление рекламным агенством"
                },
                {
                    id: 4,
                    level: "магистратура",
                    speciality: "Дизайн",
                    program: "Управление и разработка комплексных креативных стратегий"
                },
                {
                    id: 5,
                    level: "магистратура",
                    speciality: "Дизайн",
                    program: "Дизайн и продвижение цифрового продукта"
                },
                {
                    id: 6,
                    level: "магистратура",
                    speciality: "Дизайн",
                    program: "Цифровой дизайн"
                }
            ],
        },
        {
            id: "9",
            img: IT_PROFESSIONS,
            name: "ФАКУЛЬТЕТ ИНТЕРНЕТ-ПРОФЕССИЙ",
            facultets: [
                {
                    id: 1,
                    level: "магистратура",
                    speciality: "Реклама и связи с общественностью",
                    program: "Интернет-маркетинг"
                },
                {
                    id: 2,
                    level: "бакалавриат",
                    speciality: "Реклама и связи с общественностью",
                    program: "Интернет-маркетинг"
                },
                {
                    id: 3,
                    level: "бакалавриат",
                    speciality: "Менеджмент",
                    program: "Управление продуктами и проектами"
                },
                {
                    id: 4,
                    level: "бакалавриат",
                    speciality: "Дизайн",
                    program: "Веб-дизайн"
                },
                {
                    id: 5,
                    level: "бакалавриат",
                    speciality: "Информационные системы и технологии",
                    program: "Веб-разработка"
                }
            ],
        },
        {
            id: "10",
            img: DUBAE,
            name: "Мировая экономика и устойчивое развитие",
            facultets: [
                {
                    id: 1,
                    level: "дубае",
                    speciality: "-",
                    program: "-"
                }
            ],
        },
        {
            id: "11",
            img: DUBAE2,
            name: "Гостиничный и ресторанный бизнес",
            facultets: [
                {
                    id: 1,
                    level: "дубае",
                    speciality: "-",
                    program: "-"
                }
            ],
        },
        {
            id: "12",
            img: DUBAE3,
            name: "Инновационное предпринимательство",
            facultets: [
                {
                    id: 1,
                    level: "дубае",
                    speciality: "-",
                    program: "-"
                }
            ],
        },
        {
            id: "13",
            img: DUBAE4,
            name: "EMBA Стратегия и лидерство",
            facultets: [
                {
                    id: 1,
                    level: "дубае",
                    speciality: "-",
                    program: "-"
                }
            ],
        }
    ])

    const license = [
        {
            title: "Лицензия на право ведения образовательной деятельности No1900 от 28.01.2016",
            text: "Университет «Синергия» ведёт свою деятельность на основании  действующей лицензии и бессрочной государственной аккредитации.",
            text2: "Качество обучения в Университете «Синергия» остаётся на высоком уровне:  по результатам ежегодных мониторингов министерства науки и высшего образования  Российской Федерации и министерства просвещения Российской Федерации  с 2012 по 2021 год Университет «Синергия» признаётся эффективным вузом.",
            license: LICENSE_PIC,
            pdf: LICENSE_PDF
        },
        {
            title: "Свидетельство о государственной аккредитации No3110 от 15.05.2019",
            text: "Университет «Синергия» ведёт свою деятельность на основании  действующей лицензии и бессрочной государственной аккредитации.",
            text2: "Качество обучения в Университете «Синергия» остаётся на высоком уровне:  по результатам ежегодных мониторингов министерства науки и высшего образования  Российской Федерации и министерства просвещения Российской Федерации  с 2012 по 2021 год Университет «Синергия» признаётся эффективным вузом.",
            license: CERTIFICATE_PIC,
            pdf: CERTIFICATE_PDF
        },
        {
            title: "Аккредитация международной организации учебных программ MBA AMBA (Association of MBAs)",
            text: "Образовательные методики Университета отмечены престижными  наградами и аккредитацией международной ассоциации MBA.",
            text2: "",
            license: ACCREDITATION,
            pdf: ACCREDITATION_PDF
        }
    ];

    return (
        <div>
            <Context.Provider value={{
                slider, setslider,
                icons, license
            }}
            >
                {children}
            </Context.Provider>
        </div>
    );
};

export { ContextProvider, Context };