import React, { useState } from "react";
import { HeaderWrapper } from "./HeaderWrapper";
import { LOGO } from "../../assets/images_path";
import { TEL } from "../../assets/icon_path";
import { Link } from "react-router-dom";

const Header = () => {
  const [down, setdown] = useState(true);
  return (
    <HeaderWrapper>
      <header>
        <div className="container mx-auto content-top">
          <div
            onClick={() => setdown(!down)}
            className={
              down ? "menu_click menu_click_0" : "menu_click menu_click_1"
            }
          >
            <p>|</p>
            <p>|</p>
            <p>|</p>
          </div>
          <Link className="logo" to="/">
            <img className="logo" src={LOGO} alt="logo" />
          </Link>
          <a href="tel: +998(71)231-71-71">
            <img src={TEL} alt="tel" />
          </a>
        </div>

        <div
          className={
            down
              ? "container mx-auto content-bottom no"
              : "container mx-auto content-bottom yes"
          }
        >
          <Link to="/">
            <img className="logo" src={LOGO} alt="logo" />
          </Link>
          <ul>
            <li>
              <a href="#section1">Факультеты</a>
            </li>
            <li>
              <a href="#section2">О нас</a>
            </li>
            <li>
              <a href="#section3">Новости</a>
            </li>
            <li>
              <a href="tel: +998(71)231-71-71">
                <img src={TEL} alt="tel" /> +998 (71) 231-71-71
              </a>
            </li>
          </ul>
        </div>
      </header>
    </HeaderWrapper>
  );
};

export default Header;
