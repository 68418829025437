import React, { useContext } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { LicenseWrapper } from "./LicenseWrapper";
import { Context } from "../../context";

const License = () => {

  const { license } = useContext(Context)

  return (
    <LicenseWrapper>
      <div className="container mx-auto" id="license">
        <Swiper
          navigation={true} modules={[Navigation]}
          className="mySwiper"
        >
          {license?.map((el) => {
            return (
              <SwiperSlide>
                <div className="slider_div">
                  <div className="mobile_block flex justify-between">
                    <div className="text_div">
                      <h2>{el.title}</h2>
                      <div className="paragraphs flex flex-col">
                        <p>{el.text}</p>
                        <p>{el.text2}</p>
                      </div>
                      <div className="buttons flex">
                        <a
                          href={el.pdf}
                          download
                          className="download-pdf_btn"
                        >
                          Скачать pdf
                        </a>
                      </div>
                    </div>
                    <div className="license">
                      <img src={el.license} alt="" className="license_img" />
                    </div>
                    <div className="buttons flex hidden sm-block-buttons">
                      <a
                        href={el.pdf}
                        download
                        className="download-pdf_btn"
                      >
                        Скачать pdf
                      </a>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </LicenseWrapper>
  );
};

export default License;
