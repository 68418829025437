import React, { useState } from "react";
import Feedback from "./components/Feedback/Feedback";
import License from "./components/License/License";
import News from "./components/News/News";
import OurHistory from "./components/OurHistory/OurHistory";
import OurStudents from "./components/OurStudents/OurStudents";
import Universitet from "./components/universitet/Universitet";
import Footer from "./containers/footer/Footer";
import Header from "./containers/header/Header";
import Follow from "./components/Follow/Follow";
import Facultet from "./components/facultet/Facultet";
import Advantages from "./components/advantages/Advantages";
import BlueForm from "./components/blueForm/BlueForm";
import Modal from "./components/modal/Modal";
import Video from "./components/video/Video";
import About from "./components/about/About";
import AnketaB from "./components/anketaB/AnketaB";

const App = () => {
  const [modal, setmodal] = useState(false);
  return (
    <>
      <AnketaB />
      {/* modal */}
      {modal ? <Modal setstate={setmodal} /> : ""}
      {/* pags */}
      <Header />
      <Universitet setmodal={setmodal} />
      <Facultet />
      <Advantages />
      <About />
      <BlueForm />
      <Video />
      <OurStudents />
      <OurHistory />
      <License />
      <News />
      <Feedback />
      <Follow />
      <Footer />
    </>
  );
};

export default App;
