import React from "react";
import { LOGO2 } from "../../assets/images_path";
import { CaruselFacultetWrapper } from "./UniversitetWrapper";
import NumberCounter from "number-counter";

const CaruselFacultet = ({
  text,
  num1,
  num2,
  num3,
  text1,
  text2,
  text3,
  icon,
  icon1,
  setmodal,
  textBottom,
}) => {
  return (
    <CaruselFacultetWrapper>
      <div className="text">
        <h1 dangerouslySetInnerHTML={{ __html: text }} />
      </div>
      <img className="logo" src={LOGO2} alt="logo" />
      <button onClick={() => setmodal(true)}>Оставить заявку</button>
      {textBottom ? (
        <h2 className="boxs-2">{textBottom}</h2>
      ) : (
        <div className="boxs">
          <div className="box">
            <h2>{num1}</h2>
            <p>{text1}</p>
          </div>
          <div className="box">
            <h2>{num2}</h2>
            <p>{text2}</p>
          </div>
          <div className="box">
            <h2>{num3}</h2>
            <p>{text3}</p>
          </div>
        </div>
      )}

      <img className="icon" src={icon} alt="" />
      <img className="icon1" src={icon1} alt="" />
    </CaruselFacultetWrapper>
  );
};

export default CaruselFacultet;
