import React, { useState } from "react";
import {
  ICON1,
  ICON11,
  ICON12,
  ICON13,
  ICON14,
  ICON2,
  ICON3,
  ICON4,
} from "../../assets/icon_path";
import CaruselFacultet from "./CaruselFacultet";
import { UniversitetWrapper } from "./UniversitetWrapper";

const Universitet = ({ setmodal }) => {
  const [state, setstate] = useState(1);
  return (
    <UniversitetWrapper>
      <div className="container mx-auto">
        <section>
          <div className="button">
            <button
              onClick={() => setstate(1)}
              className={state === 1 ? "active" : ""}
            >
              Главная
            </button>
            <button
              onClick={() => setstate(2)}
              className={state === 2 ? "active" : ""}
            >
              Бакалавриат
            </button>
            <button
              onClick={() => setstate(3)}
              className={state === 3 ? "active" : ""}
            >
              Магистратура
            </button>
            <button
              onClick={() => setstate(4)}
              className={state === 4 ? "active" : ""}
            >
              Обучение в Дубае
            </button>
          </div>
          <div className="carusel-box">
            <div className={state === 1 ? "carusel" : "carusel-no"}>
              <CaruselFacultet
                text={
                  "Университет Синергия <br /> Приёмная комиссия <br /> Поступи прямо сейчас"
                }
                num1={187}
                text1={"престижных аккредитаций AMBA"}
                num2={24}
                text2={"выпускников корпоративных и открытых программ"}
                num3={8}
                text3={"готовых программ по личностному и бизнес-росту"}
                icon={ICON1}
                icon1={ICON14}
                setmodal={setmodal}
                textBottom={
                  "Мы объединили многолетние академические традиции и современные образовательные технологии"
                }
              />
            </div>
            <div className={state === 2 ? "carusel" : "carusel-no"}>
              <CaruselFacultet
                text={
                  "Получите степень бакалавра, обучаясь онлайн по удобному для вас графику"
                }
                num1={30}
                text1={"факультетов"}
                num2={110000}
                text2={"студентов из 89 стран мира"}
                num3={70000}
                text3={"выпускников"}
                icon={ICON2}
                icon1={ICON12}
                setmodal={setmodal}
              />
            </div>

            <div className={state === 3 ? "carusel" : "carusel-no"}>
              <CaruselFacultet
                text={
                  "Получите полное высшее образование и конкурентное преимущество на рынке труда"
                }
                num1={30}
                text1={"факультетов"}
                num2={110000}
                text2={"студентов из 89 стран мира"}
                num3={70000}
                text3={"выпускников"}
                icon={ICON3}
                icon1={ICON13}
                setmodal={setmodal}
              />
            </div>

            <div className={state === 4 ? "carusel" : "carusel-no"}>
              <CaruselFacultet
                text={
                  "Преподаватели из ЕС, США <br/> Обучение на английском языке"
                }
                num1={30}
                text1={"факультетов"}
                num2={110000}
                text2={"студентов из 89 стран мира"}
                num3={70000}
                text3={"выпускников"}
                icon={ICON4}
                icon1={ICON11}
                setmodal={setmodal}
              />
            </div>
          </div>
        </section>
      </div>
    </UniversitetWrapper>
  );
};

export default Universitet;
