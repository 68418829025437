import styled from "styled-components";
import { ICON12 } from "../../assets/icon_path";

export const AdvantagesWrapper = styled.div`
  background: #f6f8f9;
  margin-top: 100px;
  padding: 100px 0;

  background-image: url(${ICON12});
  background-position: 95% 95%;
  background-repeat: no-repeat;

  h1 {
    font-family: "Raleway", sans-serif;
    margin-bottom: 100px;
    font-weight: 700;
    font-size: 36px;
    line-height: 130%;
    color: #000000;
  }
  .contents {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 100px;
    .box {
      display: flex;
      align-items: flex-start;
      img {
        margin-right: 35px;
      }
      h4 {
        font-family: "Raleway", sans-serif;
        font-weight: 500;
        font-size: 20px;
        line-height: 130%;
        color: #000000;
      }
      p {
        margin-top: 15px;
        font-family: "Raleway", sans-serif;
        font-weight: 400;
        font-size: 17px;
        line-height: 140%;
        color: #000000;
      }
    }
  }
  @media only screen and (max-width: 1100px) {
    padding: 60px 0;
    h1 {
      margin-bottom: 60px;
      font-size: 32px;
    }
    .contents {
      display: grid;
      grid-template-columns: 1fr;
      gap: 40px;
    }
  }
  @media only screen and (max-width: 1100px) {
    background-size: 10%;
    padding: 50px 0;
    margin-top: 50px;
    h1 {
      margin-bottom: 40px;
      font-size: 20px;
    }
    .contents {
      display: grid;
      grid-template-columns: 1fr;
      gap: 30px;
      .box {
        img {
          margin-right: 20px;
        }
        h4 {
          font-size: 18px;
        }
        p {
          margin-top: 8px;
          font-size: 14px;
        }
      }
    }
  }
`;
