import React from "react";
import { VideoWrapper } from "./VideoWrapper";

const Video = () => {
  return (
    <VideoWrapper>
      <div className="container mx-auto">
        <div className="box">
          <h1>Давайте знакомиться!</h1>
          <p>
            Посмотрите наше небольшое видео, <br /> где мы расскажем, почему
            именно мы!
          </p>
        </div>
        <iframe
          src="https://www.youtube.com/embed/Bnxg5dB7KTQ"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </VideoWrapper>
  );
};

export default Video;
