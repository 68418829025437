import styled from "styled-components";

export const AnketaWrapper = styled.div`
  margin-bottom: 100px;
  .top {
    padding: 150px 0 35px 0;
    background: #e8ebf4;
    .container {
      display: flex;
      flex-wrap: wrap;
      .name {
        display: flex;
        align-items: center;
        width: 100%;
        margin-top: 20px;
        &:first-child {
          margin-top: 0;
        }
        input {
          border: none;
          border-bottom: 2px solid #352d67;
          outline: none;
          width: 100%;
          background-color: transparent;
          font-weight: 400;
          font-size: 22px;
          line-height: 120%;
          color: #352d67;
          padding: 5px;
          margin: 0 10px;
        }
        label {
          font-weight: 700;
          font-size: 25px;
          line-height: 29px;
          color: #352d67;
          white-space: nowrap;
        }
      }
      .last {
        width: 50%;
      }
    }
  }
  .bottom {
    padding: 0 0 50px 0;
    .text,
    label {
      font-family: "Raleway";
      font-style: normal;
      font-weight: 700;
      font-size: 25px;
      line-height: 29px;
      color: #352d67;
      display: flex;
      align-items: center;
    }
    label {
      cursor: pointer;
    }
    .text {
      margin-top: 30px;
    }
    .input {
      border: none;
      border-bottom: 3px solid #352d67;
      outline: none;
      background-color: transparent;
      font-weight: 400;
      font-size: 22px;
      line-height: 120%;
      color: #352d67;
      padding: 5px;
      width: 100%;
      cursor: pointer;
    }

    input[type="radio"] {
    }
    .chechkbox {
      display: flex;
      flex-wrap: wrap;
      column-gap: 10%;
      .chechkbox-in {
        width: 45%;
        margin-top: 20px;
        display: flex;
        align-items: center;
        input[type="radio"] {
          transform: scale(2);
          margin-right: 20px;
        }
        label {
          white-space: nowrap;
        }
      }
    }
  }
  button {
    background: #e4322b;
    border-radius: 2px;
    padding: 15px 75px;
    display: block;
    margin: auto;

    font-family: "Raleway";
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 29px;
    color: #fff;
  }

  @media only screen and (max-width: 1300px) {
    .bottom {
      .chechkbox {
        column-gap: 5%;
        .chechkbox-in {
          label {
            white-space: normal;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 700px) {
    margin-bottom: 40px;
    .top {
      padding: 90px 0 15px 0;
      .container {
        .name {
          input {
            font-size: 20px;
          }
          label {
            font-size: 20px;
          }
        }
        .last {
          width: 100%;
        }
      }
    }
    .bottom {
      .chechkbox {
        display: block;
        .checkbox-in {
          label {
            white-space: nowrap;
          }
        }
      }
      .text,
      label {
        font-size: 20px;
      }
    }
    button {
      font-size: 20px;
    }
  }

  @media only screen and (max-width: 500px) {
    .top {
      .container {
        .name {
          input {
            font-size: 18px;
          }
          label {
            font-size: 18px;
          }
        }
        .last {
          width: 100%;
        }
      }
    }
    .bottom {
      .chechkbox {
        display: block;
        .chechkbox-in {
          width: 100%;
          label {
            white-space: nowrap;
          }
          .management {
            white-space: normal;
          }
        }
      }
      .text,
      label {
        font-size: 18px;
      }
    }
    button {
      font-size: 18px;
    }
  }
`;
