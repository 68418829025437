import axios from "axios";
import React from "react";
import Footer from "./containers/footer/Footer";
import Header from "./containers/header/Header";
import { AnketaWrapper } from "./styles/AnketaWrapper";

const Anketa = () => {
  return (
    <>
      <Header />
      <AnketaWrapper>
        <form
          action="https://formSubmit.co/ralisher@gmail.com"
          method="post"
          encType="text/plan"
        >
          <div className="top">
            <div className="container mx-auto">
              <div className="name">
                <label htmlFor="Фамилия">Фамилия</label>
                <input type="text" name="Фамилия" id="Фамилия" required />
              </div>
              <div className="name">
                <label htmlFor="Имя">Имя</label>
                <input type="text" name="Имя" id="Имя" required />
              </div>
              <div className="name">
                <label htmlFor="Отчество">Отчество</label>
                <input type="text" name="Отчество" id="Отчество" required />
              </div>
              <div className="name last">
                <label htmlFor="Телефон">Телефон</label>
                <input type="number" name="Телефон" id="Телефон" required />
              </div>
              <div className="name last">
                <label htmlFor="рождения">Дата рождения</label>
                <input
                  type="date"
                  name="Дата_рождения"
                  id="рождения"
                  required
                />
              </div>
              <div className="name">
                <label htmlFor="mail">E-mail</label>
                <input type="email" name="E_mail" id="mail" required />
              </div>
            </div>
          </div>
          <div className="bottom">
            <div className="container mx-auto">
              <p className="text">
                Выше законченное образование на сегодняшний день:
              </p>
              <div className="chechkbox">
                <div className="chechkbox-in">
                  <input
                    type="radio"
                    id="html"
                    name="Выше_законченное_образование_на_сегодняшний_день"
                    value="11 классов"
                    required
                  />
                    <label htmlFor="html">11 классов</label>
                </div>
                <div className="chechkbox-in">
                  <input
                    type="radio"
                    id="css"
                    name="Выше_законченное_образование_на_сегодняшний_день"
                    value="СПО (колледж, техникум)"
                    required
                  />
                    <label htmlFor="css">СПО (колледж, техникум)</label> 
                </div>
                <div className="chechkbox-in">
                  <input
                    type="radio"
                    id="javascript"
                    name="Выше_законченное_образование_на_сегодняшний_день"
                    value="Бакалавриат"
                    required
                  />
                    <label htmlFor="javascript">Бакалавриат</label>
                </div>
                <div className="chechkbox-in">
                  <input
                    type="radio"
                    id="react"
                    name="Выше_законченное_образование_на_сегодняшний_день"
                    value="Магистратура"
                    required
                  />
                    <label htmlFor="react">Магистратура</label>
                </div>
              </div>
              <label htmlFor="день">
                <p className="text">
                  Ваша занимаемая должность на сегодняшний день?
                </p>
              </label>
              <input
                type="text"
                name="Ваша_занимаемая_должность_на_сегодняшний_день"
                id="день"
                className="input"
                required
              />
              <label htmlFor="день2">
                <p className="text">
                  Ваша желаемая должность на сегодняшний день?
                </p>
              </label>
              <input
                type="text"
                name="Ваша_желаемая_должность_на_сегодняшний_день"
                id="день2"
                className="input"
                required
              />
              <p className="text">
                В каком направлении Вы планируете развиваться в ближайшие 5 лет:
              </p>
              <div className="chechkbox">
                <div className="chechkbox-in">
                  <input
                    type="radio"
                    id="Управление"
                    name="В_каком_направлении_Вы_планируете_развиваться_в_ближайшие_5_лет"
                    value="Управление"
                    required
                  />
                    <label htmlFor="Управление">Управление</label>
                </div>
                <div className="chechkbox-in">
                  <input
                    type="radio"
                    id="Дизайн"
                    name="В_каком_направлении_Вы_планируете_развиваться_в_ближайшие_5_лет"
                    value="Дизайн"
                    required
                  />
                    <label htmlFor="Дизайн">Дизайн</label> 
                </div>
                <div className="chechkbox-in">
                  <input
                    type="radio"
                    id="Интернет маркетинг"
                    name="В_каком_направлении_Вы_планируете_развиваться_в_ближайшие_5_лет"
                    value="Интернет маркетинг"
                    required
                  />
                  <label htmlFor="Интернет маркетинг">Интернет маркетинг</label>
                </div>
                <div className="chechkbox-in">
                  <input
                    type="radio"
                    id="Предпринимательство"
                    name="В_каком_направлении_Вы_планируете_развиваться_в_ближайшие_5_лет"
                    value="Предпринимательство"
                    required
                  />
                  <label htmlFor="Предпринимательство">
                    Предпринимательство
                  </label>
                </div>
                <div className="chechkbox-in">
                  <input
                    type="radio"
                    id="Экономика"
                    name="В_каком_направлении_Вы_планируете_развиваться_в_ближайшие_5_лет"
                    value="Экономика"
                    required
                  />
                  <label htmlFor="Экономика">Экономика</label>
                </div>
                <div className="chechkbox-in">
                  <input
                    type="radio"
                    id="Информационные технологии"
                    name="В_каком_направлении_Вы_планируете_развиваться_в_ближайшие_5_лет"
                    value="Информационные технологии"
                    required
                  />
                  <label htmlFor="Информационные технологии">
                    Информационные технологии
                  </label>
                </div>
                <div className="chechkbox-in">
                  <input
                    type="radio"
                    id="Реклама"
                    name="В_каком_направлении_Вы_планируете_развиваться_в_ближайшие_5_лет"
                    value="Реклама"
                    required
                  />
                  <label htmlFor="Реклама">Реклама</label>
                </div>
                <div className="chechkbox-in">
                  <input
                    type="radio"
                    id="Лингвистика"
                    name="В_каком_направлении_Вы_планируете_развиваться_в_ближайшие_5_лет"
                    value="Лингвистика"
                    required
                  />
                  <label htmlFor="Лингвистика">Лингвистика</label>
                </div>
                <div className="chechkbox-in">
                  <input
                    type="radio"
                    id="Спортивныцй маркетинг"
                    name="В_каком_направлении_Вы_планируете_развиваться_в_ближайшие_5_лет"
                    value="Спортивныцй маркетинг"
                    required
                  />
                  <label htmlFor="Спортивныцй маркетинг">
                    Спортивныцй маркетинг
                  </label>
                </div>
                <div className="chechkbox-in">
                  <input
                    type="radio"
                    id="Юриспруденция"
                    name="В_каком_направлении_Вы_планируете_развиваться_в_ближайшие_5_лет"
                    value="Юриспруденция"
                    required
                  />
                  <label htmlFor="Юриспруденция">Юриспруденция</label>
                </div>
                <div className="chechkbox-in">
                  <input
                    type="radio"
                    id="Менеджемент в гостинечноми ресторанном бизнесе"
                    name="В_каком_направлении_Вы_планируете_развиваться_в_ближайшие_5_лет"
                    value="Менеджемент в гостинечноми ресторанном бизнесе"
                    required
                  />
                  <label
                    className="management"
                    htmlFor="Менеджемент в гостинечноми ресторанном бизнесе"
                  >
                    Менеджемент в гостинечноми ресторанном бизнесе
                  </label>
                </div>
                <div className="chechkbox-in">
                  <input
                    type="radio"
                    id="Ваш вариант"
                    name="В_каком_направлении_Вы_планируете_развиваться_в_ближайшие_5_лет"
                    value="Ваш вариант"
                    required
                  />
                  <label htmlFor="Ваш вариант">
                    <p>Ваш вариант</p>
                  </label>
                  <input
                    type="text"
                    name="Ваш_вариант"
                    className="input ml-3"
                  />
                </div>
              </div>
              <label htmlFor="лестнице">
                <p className="text">
                  Что сейчас необходимо вам для продвижения по карьерной
                  лестнице?
                </p>
              </label>
              <input
                type="text"
                name="Что_сейчас_необходимо_вам_для_продвижения_по_карьерной_лестнице"
                id="лестнице"
                className="input"
                required
              />
              <label htmlFor="ВУЗА">
                <p className="text">Что для Вас важно при выборе ВУЗА?</p>
              </label>
              <input
                type="text"
                name="Что_для_Вас_важно_при_выборе_ВУЗА"
                id="ВУЗА"
                className="input"
                required
              />

              <p className="text">Какая программа обучения Вам подходит?</p>
              <div className="chechkbox">
                <div className="chechkbox-in">
                  <input
                    type="radio"
                    id="Дневная"
                    name="Какая_программа_обучения_Вам_подходит"
                    value="Дневная"
                    required
                  />
                    <label htmlFor="Дневная">Дневная</label>
                </div>
                <div className="chechkbox-in">
                  <input
                    type="radio"
                    id="Вечерняя"
                    name="Какая_программа_обучения_Вам_подходит"
                    value="Вечерняя"
                    required
                  />
                    <label htmlFor="Вечерняя">Вечерняя</label> 
                </div>
                <div className="chechkbox-in">
                  <input
                    type="radio"
                    id="Выходной день"
                    name="Какая_программа_обучения_Вам_подходит"
                    value="Выходной день"
                    required
                  />
                    <label htmlFor="Выходной день">Выходной день</label>
                </div>
                <div className="chechkbox-in">
                  <input
                    type="radio"
                    id="Заочная"
                    name="Какая_программа_обучения_Вам_подходит"
                    value="Заочная"
                    required
                  />
                    <label htmlFor="Заочная">Заочная</label>
                </div>
                <div className="chechkbox-in">
                  <input
                    type="radio"
                    id="Дистанционная"
                    name="Какая_программа_обучения_Вам_подходит"
                    value="Дистанционная"
                    required
                  />
                    <label htmlFor="Дистанционная">Дистанционная</label>
                </div>
              </div>

              <div className="chechkbox">
                <div className="chechkbox-in">
                  <label htmlFor="data2">Дата</label>
                  <input
                    type="date"
                    name="Дата"
                    id="data2"
                    className="input"
                    required
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="container mx-auto">
            <button type="submit">отправить</button>
          </div>
        </form>
      </AnketaWrapper>
      <Footer />
    </>
  );
};

export default Anketa;
