import React, { useContext, useState } from "react";
import { FacultetWrapper } from "./FacultetWrapper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper";
import useWindowSize from "../../hooks/useWindowSize";
import More from "../more/More"
import { Context } from "../../context";

const Facultet = () => {
  const [state, setstate] = useState("Все");
  const [facultet, setFacultet] = useState([])
  const [name, setName] = useState()
  const [more, setMore] = useState(false)
  const { width } = useWindowSize();

  const { slider } = useContext(Context)

  return (
    <>
      {more ? (
        <More
          setMoreModal={setMore}
          name={name}
          facultet={facultet}
          slider={slider}
          state={state}
        />
      ) : (
        ""
      )}
      <FacultetWrapper>
        <div className="container mx-auto" id="section1">
          <h1>Найди то, что тебе нужно</h1>
          <div className="button">
            <button
              onClick={() => setstate("Все")}
              className={state === "Все" ? "active" : ""}
            >
              Все факультеты
            </button>
            <button
              onClick={() => setstate("бакалавриат")}
              className={state === "бакалавриат" ? "active" : ""}
            >
              Бакалавриат
            </button>
            <button
              onClick={() => setstate("магистратура")}
              className={state === "магистратура" ? "active" : ""}
            >
              Магистратура
            </button>
            <button
              onClick={() => setstate("дубае")}
              className={state === "дубае" ? "active" : ""}
            >
              Обучение в Дубае
            </button>
          </div>
          <Swiper
            slidesPerView={
              width > 1280 ? 3 : width > 900 ? 3 : width > 500 ? 2 : 1.5
            }
            spaceBetween={30}
            slidesPerGroup={1}
            loop={false}
            loopFillGroupWithBlank={true}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Pagination, Navigation]}
            className="mySwiper"
          >
            {slider?.map((v) => {
              if (state != "Все") {
                if (v.facultets.some((item) => item.level == state)) {
                  return (
                    <SwiperSlide>
                      <img src={v.img} alt="facultet" />
                      <div className="box">
                        <p>{v.name}</p>
                        <h3>{v.text}</h3>
                        <button
                          onClick={() => {
                            setMore(true);
                            setName(v.name);
                            setFacultet(v.facultets);
                          }}
                        >
                          Подробнее
                        </button>
                      </div>
                    </SwiperSlide>
                  );
                }
              } else {
                return (
                  <SwiperSlide>
                    <img src={v.img} alt="facultet" />
                    <div className="box">
                      <p>{v.name}</p>
                      <h3>{v.text}</h3>
                      <button
                        onClick={() => {
                          setMore(true);
                          setName(v.name);
                          setFacultet(v.facultets);
                        }}
                      >
                        Подробнее
                      </button>
                    </div>
                  </SwiperSlide>
                );
              }
            })}
          </Swiper>
        </div>
      </FacultetWrapper>
    </>
  );
};

export default Facultet;
