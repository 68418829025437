import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const AnketaBWrapper = styled.div`
  position: fixed;
  bottom: 80px;
  right: 40px;
  z-index: 99999999999999;
  a {
    padding: 20px 40px;
    background: #e4322b;
    border-radius: 10px;
    font-size: 24px;
    line-height: 140%;
    color: #ffffff;
    text-transform: uppercase;
  }
  @media only screen and (max-width: 1000px) {
    a {
      padding: 15px 30px;
      font-size: 20px;
    }
  }
  @media only screen and (max-width: 500px) {
    bottom: 35px;
    right: 25px;
    a {
      padding: 12px 25px;
      font-size: 16px;
    }
  }
`;

const AnketaB = () => {
  return (
    <AnketaBWrapper>
      <Link to="/anketa">анкета</Link>
    </AnketaBWrapper>
  );
};

export default AnketaB;
