import React from 'react'
import { OurHistoryWrapper } from './OurHistoryWrapper';

const OurHistory = () => {
    return (
        <OurHistoryWrapper>
            <div className="gradient-background" id="our-history">
                <div className="gradient">
                    <h2>SYNERGY <br /> UNIVERSITY</h2>
                    <div className="container mx-auto texts flex justify-between items-center  flex-col">
                        <h3 className="py-8">Наша история</h3>
                        <div className="flex gap-x-5">
                            <div className="text-div flex flex-col">
                                <p>Университет входит в структуру Корпорации «Синергия», которая работает на российском  рынке образования более 30 лет. За это время мы кратно выросли как по числу  и масштабу реализованных  проектов, так и по числу сотрудников в нашей команде.  Достижения прошлых лет вдохновляют нас на победы в настоящем и будущем.
                                </p>
                                <p>1988
                                    Основана первая в России бизнес- школа «Синергия». Главная задача школы — подготовка управленческих кадров и предпринимателей в условиях новой экономики.
                                </p>
                                <p>1995
                                    Основан Московский финансово-промышленный университет (МФПУ).
                                </p>
                                <p>2004
                                    Открыт Центр развития  карьеры — структурное  подразделение  Университета, специалисты  которого помогают  студентам ориентироваться  на рынке труда, оценить  свой потенциал  и найти высокооплачиваемую  работу в компаниях- партнёрах. Началось  потоковое трудоустройство  студентов в компании- партнёры вуза.
                                </p>
                                <p>2010
                                    Бизнес-школа «Синергия» вошла
                                    в состав Московского финансово- промышленного университета. Университет получил новое название — МФПУ «Синергия» (Университет «Синергия»). Университет выиграл  конкурс Министерства  образования и науки РФ  и Оргкомитета «Сочи 2014»  на создание Центра  подготовки волонтёров   для XXII Олимпийских зимних  игр.
                                </p>
                                <p>2014
                                    Более 2000 волонтёров из  Центра подготовки волонтё- ров Университета «Синергия»  приняли участие в организа- ции XXII Олимпийских зимних  игр и XI Паралимпийских зимних  игр в Сочи.
                                </p>
                            </div>
                            <div className="text-div text-hidden_div flex flex-col">                                
                                <p>2020
                                    В начале пандемии  Университет «Синергия»  запустил платформу для  проведения онлайн- трансляций Synergy.Online  и перевел в дистанционный  формат все события. За год  было проведено более 200  событий, в том числе для  госзаказчиков.
                                    Развитие коллабораций  с лидерами индустрий: старт  проекта «Новая Школа  МХАТ» совместно с МХАТ  им. Горького, запуск факультета  анимации совместно  с «Союзмультфильмом».
                                    Университет «Синергия»  получил статус Федеральной  инновационной площадки  (ФИП), утверждённый  приказом министерства  образования и науки РФ.
                                </p>
                                <p>2021
                                    Университет «Синергия» занял  1-е место в рейтинге пред- принимательских программ  Национального рейтинга  университетов «Интерфакса»  с образовательными программа- ми «Международное предпри- нимательство»   и «Предпринимательство».
                                    Университет «Синергия» вошел  в рейтинг крупнейших компаний  в сфере онлайн-образования  EdTech, заняв 9-е место.
                                    Студенты Университета «Синер- гия» вошли в число победителей  Всероссийского студенческого  конкурса «Твой Ход». В качестве  приза каждый из них получил  денежную премию в размере  1 млн рублей. Кроме того, ребята  вошли в число 50 студентов,  показавших лучшие результаты,  за что были награждены допол- нительным грантом на развитие  университета на сумму 2,5 млн  рублей.
                                    В Университете открылись  факультеты робототехники,  технологического предпри- нимательства и медицинский  факультет.
                                    Университет «Синергия»,  как и в прошлом году, попал  в число 450 сильнейших универ- ситетов.
                                    Университет «Синергия» стал  организатором консорциума «Бу- дущее арктической архитектуры  и динамика климата».
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </OurHistoryWrapper>
    )
}

export default OurHistory