import React from "react";
import { OurStudentsWrapper } from "./OurStudentsWrapper";

const OurStudents = () => {
  return (
    <OurStudentsWrapper>
      <div className="container mx-auto mt-8" id="section2">
        <div className="flex justify-between items-center">
          <div className="text">
            <h1>
              Наши <br /> студенты
            </h1>
            <p>
              Мы требовательны к себе, чтобы каждый день становится лучше для
              наших клиентов и находить лучшие решения для каждого из них
            </p>
          </div>
          <div className="number flex  text-start flex-col">
            <h3>Счетчик студентов</h3>
            <h2 className="number-bg">985</h2>
          </div>
        </div>
      </div>
    </OurStudentsWrapper>
  );
};

export default OurStudents;
