import styled from "styled-components";
import { DOTS } from "../../assets/images_path";

export const UniversitetWrapper = styled.div`
  background-image: url(${DOTS});
  background-repeat: no-repeat;
  background-position-x: 5%;
  background-position-y: 50px;
  .container {
    margin-top: 125px;
    .button {
      button {
        font-family: sans-serif;
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        color: #000000;
        margin-right: 40px;
        transition: all 0.4s linear;
        &:last-child {
          margin-right: 0;
        }
        position: relative;
        &::after {
          position: absolute;
          content: "";
          height: 2px;
          left: 0;
          bottom: -3px;
          width: 15px;
          background-color: #000000;
          transition: all 0.4s linear;
        }
      }
      .active {
        transition: all 0.4s linear;
        color: #fe334a;
        &::after {
          position: absolute;
          content: "";
          height: 2px;
          left: 0;
          bottom: -3px;
          width: 100%;
          background-color: #fe334a;
          transition: all 0.4s linear;
        }
      }
    }

    .carusel-box {
      display: flex;
      margin-top: 20px;
      .carusel {
        width: 100%;
        transition: all 0.4s linear;
        padding: 25px 0;
        display: block;
      }
      .carusel-no {
        width: 100%;
        padding: 25px 0;
        transition: all 0.4s linear;
        display: none;
      }
    }
  }
  @media only screen and (max-width: 800px) {
    .container {
      .button {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 30px;
        button {
          width: max-content;
          font-size: 24px;
          line-height: 29px;
          margin: auto;
          &:last-child {
            margin: auto;
          }
          &::after {
            height: 2px;
            bottom: -3px;
            width: 15px;
          }
        }
        .active {
          &::after {
            height: 2px;
            bottom: -3px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 500px) {
    background-image: none;
    .container {
      margin-top: 90px;
      .button {
        gap: 10px;
        row-gap: 25px;
        button {
          width: max-content;
          font-size: 20px;
          line-height: 22px;
        }
      }
    }
  }
`;

export const CaruselFacultetWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  .text {
    margin-top: 70px;
    width: calc(100% - 380px);
    h1 {
      font-family: "Raleway", sans-serif;
      font-weight: 600;
      font-size: 55px;
      line-height: 130%;
      color: #000000;
    }
  }
  .logo {
    width: 380px;
  }
  button {
    width: 310px;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #e4322b;
    border-radius: 2px;

    font-family: "Raleway", sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 140%;

    color: #ffffff;
  }
  .boxs-2 {
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    font-size: 28px;
    text-align: end;
    line-height: 130%;
    color: #000000;
    max-width: 970px;
    margin: 35px 0;
  }
  .boxs {
    display: flex;
    align-items: flex-start;
    margin-top: 20px;
    p {
      margin-top: 20px;
      font-family: "Raleway", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 19px;
      line-height: 130%;
      color: #000000;
    }
    .box {
      max-width: 190px;
      text-align: center;
      margin-right: 50px;
      &:last-child {
        margin-right: 0px;
      }
      h2 {
        font-family: "Raleway", sans-serif;
        font-weight: 600;
        font-size: 55px;
        line-height: 130%;
        color: #000000;
      }
    }
  }
  .icon {
    position: absolute;
    top: -90px;
    right: -10px;
    z-index: 1;
  }
  .icon1 {
    position: absolute;
    bottom: 0;
    left: 10px;
    z-index: -1;
  }
  @media only screen and (max-width: 1050px) {
    .logo {
      display: none;
    }
    .text {
      order: 2;
      margin-top: 20px;
      width: 100%;
      text-align: center;
      min-height: 220px;
      h1 {
        font-size: 38px;
      }
    }
    button {
      width: 310px;
      order: 3;
      margin: auto;
      margin-top: 10px;
    }
    .boxs-2 {
      font-size: 28px;
      margin: 30px 0;
      text-align: center;
      order: 4;
    }
    .boxs {
      width: 100%;
      order: 4;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      .box {
        max-width: 190px;
        text-align: center;
        margin-right: 50px;
        &:last-child {
          margin-right: 0px;
        }
      }
    }
    .icon {
      z-index: -1;
    }
  }
  @media only screen and (max-width: 500px) {
    .logo {
      width: 230px;
      display: block;
      margin: auto;
    }
    .text {
      order: 2;
      margin-top: 20px;
      width: 100%;
      text-align: center;
      min-height: 100px;
      h1 {
        font-size: 22px;
      }
    }
    button {
      width: 250px;
      height: 50px;
      order: 3;
      margin: auto;
      margin-top: 10px;
      font-size: 18px;
    }
    .boxs-2 {
      font-size: 20px;
      margin: 20px 0;
      text-align: center;
    }
    .boxs {
      width: 100%;
      order: 4;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      align-items: flex-start;
      justify-content: center;
      .box {
        max-width: 190px;
        text-align: center;
        margin-right: 10px;
        &:last-child {
          margin-right: 0px;
        }
        h2 {
          font-size: 30px;
        }
        p {
          margin-top: 10px;
          font-size: 14px;
        }
      }
    }
    .icon {
      display: none;
    }
    .icon1 {
      transform: scale(0.4);
      bottom: 17%;
    }
  }
`;
