import React from "react";
import Form from "../Form/Form";
import { ModalWrapper } from "./ModalWrapper";

const Modal = ({ setstate }) => {
  return (
    <ModalWrapper>
      <div className="boxs">
        <Form modal={true} setstate={setstate} />
      </div>
    </ModalWrapper>
  );
};

export default Modal;
