import styled from "styled-components";

export const FooterWrapper = styled.div`
  footer {
    background: #141516;
    color: #fff;
    padding: 50px 0;
    .container {
      .footer-over {
        .footer-logo {
          .links {
            .logo {
              width: 300px;
            }
          }
          .footer_icons {
            h3 {
              font-weight: 500;
              font-size: 24px;
              line-height: 29px;
            }
            .icons {
              margin-top: 30px;
              .icon {
                height: 52px;
                width: 52px;
                background: #fff;
                border-radius: 50%;
                padding: 12px;
                text-align: center;
                display: flex;
                align-items: center;
              }
            }
          }
        }
        .footer-menu {
          margin-top: 100px;
          h3 {
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            margin-bottom: 30px;
          }
          p {
            font-weight: 200;
            font-size: 18px;
            line-height: 144.69%;
            margin-bottom: 20px;
          }
        }
        .line {
          height: 1px;
          background: #FFFFFF;
          border-radius: 10px;
          margin-top: 100px;
        }
        .footer-year {
          margin-top: 30px;
          p {
            font-weight: 200;
            font-size: 16px;
            line-height: 21px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 700px) {
    footer {
      .container {
        .footer-over {
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          flex-direction: column;
          .footer-logo {
            .logo {
              width: 150px;
              height: 35px;
            }
            .footer_icons {
              display: none;
            }
          }
          
          .footer-menu {
            display: block;
            margin-top: 30px;
            .contact-adress_div {
              .address_div {
                margin: 0;
              }
            }
            p {
              /* display: none; */
            }
          }
          .line {
            height: 1px;
            background: #FFFFFF;
            border-radius: 10px;
            margin: 0;
          }
        }
      }
      
    }
    
  }
`;
