import styled from 'styled-components';

export const AboutWrapper = styled.div`
    height: auto;
    overflow-x: hidden;
    .top__div {
        p {
            font-weight: 300;
            font-size: 20px;
            line-height: 24px;
            margin: 30px 0 0 30px;
        }
    }
    .about_contain {
        margin-left: 50px;
        .about_over {
            margin-top: 50px;
            .lms_platfrom {
                width: min-content;
                h1 {
                    font-weight: 700;
                    font-size: 32px;
                    line-height: 42px;
                    color: #000000;
                }
                h3 {
                    font-weight: 400;
                    font-size: 22px;
                    line-height: 26px;
                    word-wrap: break-word;
                    color: #000000;
                    margin: 30px 0;
                }
                
                .about-education {
                    margin-top: 50px;
                    h5 {
                        font-weight: 700;
                        font-size: 21px;
                        line-height: 25px;
                    }
                    .text-list {
                        margin: 30px 0;
                        .fife__seven {
                            margin-left: 30px;
                        }
                        .list {
                            margin: 30px 0;
                            h1 {
                                font-family: 'Oswald', sans-serif;
                                font-weight: 700;
                                font-size: 40px;
                                line-height: 59px;
                                color: #ED1B23;
                            }
                            p {
                                font-weight: 200;
                                font-size: 18px;
                                line-height: 22px;
                                width: 350px;
                                margin-left: 10px;
                            }
                        }
                    }
                }
            }
            .lms_system {
                margin-top: -50px;
                h2 {
                    font-weight: 700;
                    font-size: 25px;
                    line-height: 30px;
                }
                .lms_ul {
                    margin: 20px 0 0 30px;
                    width: 355px;
                    li {
                        list-style: disc;
                        margin: 10px 0;
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 22px;
                    }
                }
            }
        }
    }
    .imgs {
        .tablet {
            position: relative;
            right: 30px;
            .removebg_about {
                width: 78px;
                height: 163px;
                position: absolute;
                bottom: 60px;
                left: 52.5%;
            }
            .tablet_img {
                position: relative;
                right: -40px;
            }
        }
        .laptop {
            position: relative;
            top: -70px;
            left: 5%;
            .triangle {
                width: 170px;
                height: 246px;
                position: absolute;
                bottom: -10px;
                left: -100px;
                z-index: -1;
            }                        
            .laptop_img {
                z-index: 2;
            }
        }
    }

    @media only screen and (max-width: 1200px) {
        .about_contain {
            margin-left: 30px;
            .about_over {
                display: block;
                .lms_platfrom {
                    width: auto;
                    h1 {
                        font-size: 22px;
                        line-height: 26px;
                    }
                    h3 {
                        font-size: 18px;
                        line-height: 22px;
                        width: auto;
                    }
                    .about-education {
                        h5 {
                            text-align: center;
                        }
                        .text-list {
                            justify-content: space-around;   
                            .one__four, .fife__seven {
                                .list {
                                    p {
                                        font-size: 18px;
                                        word-wrap: break-word;
                                    }
                                }
                            }                         
                            .fife__seven {
                                margin: 0;
                            }
                        }
                    }
                }
                .lms_system {
                    margin: 0;
                    .imgs {
                        display: none;
                    }
                }
            }
        }
    }    

    @media only screen and (max-width: 700px) {
        .about_contain {
            margin: 0 15px;
            .about_over {
                display: block;
                .lms_platfrom {
                    width: auto;
                    h1 {
                        font-size: 22px;
                        line-height: 26px;
                    }
                    h3 {
                        font-size: 18px;
                        line-height: 22px;
                        width: auto;
                    }
                    .about-education {
                        width: 294px;
                        .text-list {
                            display: block;
                            .one__four, .fife__seven {
                                .list {
                                    p {
                                        font-size: 16px;
                                        line-height: 22px;
                                        word-wrap: break-word;
                                    }
                                }                                
                            }
                            .fife__seven {
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 500px) {
        overflow-x: hidden;
        .about_contain {
            margin: 0 7px;
            .about_over {
                display: block;
                .lms_platfrom {
                    width: auto;
                    h1 {
                        font-size: 22px;
                        line-height: 26px;
                    }
                    h3 {
                        font-size: 16px;
                        line-height: 22px;
                        width: auto;
                        word-wrap: break-word;
                    }
                    .about-education {
                        .text-list {
                            display: block;
                            .one__four, .fife__seven {
                                .list {
                                    p {
                                        font-size: 16px;
                                        line-height: 22px;
                                        word-wrap: break-word;
                                    }
                                }
                            }
                            .fife__seven {
                                margin: 0;
                            }
                        }
                    }
                }
                .lms_system {
                    h5 {
                        font-size: 25px;
                        line-height: 30px;
                    }
                    .lms_ul {
                        width: 290px;
                        li {
                            font-size: 16px;
                            line-height: 22px;
                        }
                    }
                    .imgs {
                        display: block;
                        height: 40px;
                        .tablet {
                            position: relative;  
                            .removebg_about {
                                width: 78px;
                                height: 163px;
                                position: absolute;
                                bottom: 1238px;
                                left: 91%;
                            }
                            .tablet_img {
                                display: none;
                            }
                        }
                        .laptop {
                            position: relative;
                            top: -395px;
                            left: 82%;
                            .triangle {
                                display: none;
                            }                        
                            .laptop_img {
                                z-index: -2;
                                height: 133px;
                                width: 238px;
                            }
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 350px) {
        .about_contain {
            margin: 0 15px;
            .about_over {
                display: block;
                .lms_platfrom {
                    width: auto;
                    h1 {
                        font-size: 22px;
                        line-height: 26px;
                    }
                    h3 {
                        font-size: 18px;
                        line-height: 22px;
                        width: auto;
                    }
                    .about-education {
                        .text-list {
                            display: block;
                            .one__four, .fife__seven {
                                .list {
                                    p {
                                        font-size: 14px;
                                        line-height: 22px;
                                        word-wrap: break-word;
                                    }
                                }
                            }
                            .fife__seven {
                                margin: 0;
                            }
                        }
                    }
                }
                .lms_system {
                    h5 {
                        font-size: 25px;
                        line-height: 30px;
                    }
                    .lms_ul {
                        width: 290px;
                        li {
                            font-size: 14px;
                            line-height: 22px;
                            word-wrap: break-word;
                        }
                    }
                    .imgs {
                        display: block;
                        .tablet {
                            position: relative;  
                            
                            .removebg_about {
                                width: 78px;
                                height: 163px;
                                position: absolute;
                                bottom: 1270px;
                                left: 80%;
                            }
                            .tablet_img {
                                display: none;
                            }
                        }
                        .laptop {
                            position: relative;
                            top: -380px;
                            left: 80%;
                            .triangle {
                                display: none;
                            }                        
                            .laptop_img {
                                z-index: -2;
                                height: 133px;
                                width: 238px;
                            }
                        }
                    }
                }
            }
        }
    }
`