import React, { useState } from "react";
import { G1, G2, G3, G4, G5, G6 } from "../../assets/icon_path";
import { AdvantagesWrapper } from "./AdvantagesWrapper";

const Advantages = () => {
  const [state, setstate] = useState([
    {
      id: "1",
      img: G1,
      name: "Онлайн-обучение",
      text: "Учитесь в любое удобное время. Получайте знания находясь дома, на работе или в поездке. Система обучения открыта 24/7.",
    },
    {
      id: "2",
      img: G2,
      name: "Сопровождение студента во время обучения",
      text: "Сопровождение студента от подачи документов до получения диплома.",
    },
    {
      id: "3",
      img: G3,
      name: "Сами подготовим все документы",
      text: "Мы подготовим документы самостоятельно. Вам нужно будет только подписать и отправить обратно в приложенном конверте.",
    },
    {
      id: "4",
      img: G4,
      name: "Доставка документов",
      text: `Бесплатно отправим документы посредством  "UzPost" до ближайшего отделения либо закажите курьерскую доставку.`,
    },
    {
      id: "5",
      img: G5,
      name: "Ускоренное обучение",
      text: "Если диплом нужен срочно, возможно обучение по ускоренной интенсивной программе. Сокращение срока обучения вдвое.",
    },
    {
      id: "6",
      img: G6,
      name: "Общайтесь как Вам удобно",
      text: "Пишите нам в социальные сети, мессенджеры, чат на сайте или позвоните по телефону. Вам доступны различные каналы связи.",
    },
  ]);
  return (
    <AdvantagesWrapper>
      <div className="container mx-auto">
        <h1>Преимущества Synergy University</h1>
        <div className="contents">
          {state.map((v) => (
            <div kay={v.id} className="box">
              <img src={v.img} alt="" />
              <div className="">
                <h4>{v.name}</h4>
                <p>{v.text}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </AdvantagesWrapper>
  );
};

export default Advantages;
