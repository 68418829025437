import React from "react";
import {
  SINERGY,
  SINERGY_2,
  SINERGY_3,
  SINERGY_4,
} from "../../assets/images_path";
import { NewsWrapper } from "./NewsWrapper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import useWindowSize from "../../hooks/useWindowSize";

const News = () => {
  const { width } = useWindowSize();
  const news = [
    {
      id: 1,
      img: SINERGY,
      date: "23. 03. 2021",
      title:
        "Уважаемые коллеги! Готовы к выдаче свидетельства о прохождении первичной аккредитации по специальностям «Лечебное дело»...",
    },
    {
      id: 2,
      img: SINERGY_2,
      date: "23. 05. 2021",
      title:
        "20 июля российская национальная сеть проекта Европейского регионального бюро Всемирной организации здравоохранения «Здоровые города»...",
    },
    {
      id: 3,
      img: SINERGY_3,
      date: "23. 03. 2021",
      title: "Актуальное в Российской имплантологии, Кавказ-2021",
    },
    {
      id: 4,
      img: SINERGY_4,
      date: "23. 03. 2021",
      title:
        "Выпуск иностранных врачей направлений подготовки «Лечебное дело» и «Стоматология»",
    },
    {
      id: 5,
      img: SINERGY,
      date: "23. 03. 2021",
      title:
        "Уважаемые коллеги! Готовы к выдаче свидетельства о прохождении первичной аккредитации по специальностям «Лечебное дело»...",
    },
    {
      id: 6,
      img: SINERGY_2,
      date: "23. 05. 2021",
      title:
        "20 июля российская национальная сеть проекта Европейского регионального бюро Всемирной организации здравоохранения «Здоровые города»...",
    },
    {
      id: 7,
      img: SINERGY_3,
      date: "23. 03. 2021",
      title: "Актуальное в Российской имплантологии, Кавказ-2021",
    },
    {
      id: 8,
      img: SINERGY_4,
      date: "23. 03. 2021",
      title:
        "Выпуск иностранных врачей направлений подготовки «Лечебное дело» и «Стоматология»",
    },
  ];

  return (
    <NewsWrapper>
      <div className="container mx-auto" id="section3">
        <h2>Новости</h2>
        <div className="cards flex justify-around">
          <Swiper
            slidesPerView={
              width > 1280 ? 4 : width > 900 ? 3 : width > 500 ? 2 : 1.5
            }
            spaceBetween={20}
            slidesPerGroup={1}
            loop={false}
            loopFillGroupWithBlank={true}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Pagination, Navigation]}
            className="mySwiper"
          >
            {news?.map((el) => {
              return (
                <SwiperSlide>
                  <div className="card">
                    <img src={el.img} alt="" />
                    <p>{el.date}</p>
                    <h4>{el.title}</h4>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </NewsWrapper>
  );
};

export default News;
