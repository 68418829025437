import styled from 'styled-components';
import { ICON12 } from '../../assets/icon_path';
import { REMOVEBG_LICENSE } from '../../assets/images_path';

export const LicenseWrapper = styled.div`
    background-image: url(${REMOVEBG_LICENSE});
    background-repeat: no-repeat;
    background-position: bottom right;    
    height: auto;
    margin-top: 100px;
    .slider_div {        
        width: 100%;
        height: 650px;
        .text_div {
            h2 {
                width: 580px;
                font-size: 36px;
                line-height: 130%;
                color: #000000;
            }
        }
        .paragraphs {
            margin: 10% 0;
            p {
                width: 580px;
                margin: 15px 0;
                font-weight: 400;
            }
        }
        .buttons {
            button, a {
                padding: 18px 35px;
                border-radius: 6px;
                font-weight: 300;
                font-family: 'Raleway', sans-serif;
            }
            .download-pdf_btn {
                background: #00249E;
                color: white;
            }
        }
        .license {
            margin-right: 10%;
            img {
                width: 384px;
                height: 573px;
            }        
        }
        .arrows {
            margin-right: 18%;
            img {
                cursor: pointer;
            }
        }
    }
    .swiper-button-next,
    .swiper-button-prev {
        top: 94%;
        color: #000000;
        z-index: 99;
        border-radius: 50%;
        border: 1px solid #000000;
        width: 50px;
        height: 50px;
    }
    .swiper-button-next:after,
    .swiper-button-prev:after {
        font-size: 24px;        
    }
    .swiper-button-next {
        right: 20%;
        top: 95%;
    }
    .swiper-button-prev {
        left: 69%;
        top: 95%;
    }
    .swiper-pagination-bullet {            
        display: none;
    }

    @media only screen and (max-width: 1200px) {
        .slider_div {
            height: 750px;
            .mobile_block {
                justify-content: space-around;
                .text_div {
                    width: 450px;
                    h2 {
                        width: 100%;
                        font-size: 25px;
                    }
                }
                .paragraphs {
                    p {
                        width: 100%;
                        font-size: 16px;
                        margin: 10px 0; 
                    }
                }
                .buttons {
                    button, a {
                        padding: 15px;
                    }
                }
                .license {
                    margin: 0;
                }
            }
            
        }
        .swiper-button-next {
            right: 18%;
            top: 83%;
        }
        .swiper-button-prev {
            left: 65%;
            top: 83%;
        }
    }
    

    @media only screen and (max-width: 900px) {
        background-image: url(${ICON12});
        background-repeat: no-repeat;
        background-position: right 7%;       
        margin-top: 30px;        
        .slider_div {            
            height: auto;
            .mobile_block {
                display: block;
                flex-direction: column;
                .text_div {
                    h2 {
                        width: 100%;
                        font-weight: 700;
                        font-size: 25px;
                    }
                    .paragraphs, .buttons {
                        display: none;
                    }
                }
                .license {
                    margin-top: 60px;
                    margin-right: 0;
                    img {
                        width: 100%;
                    }
                }
                .sm-block-buttons {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 100px;
                    button, a {
                        padding: 10px 15px;
                    }
                }
            }
            
        }
        .swiper-button-next {
            right: 30%;
            top: 93%;
        }
        .swiper-button-prev {
            left: 30%;
            top: 93%;
        }
    }

    @media only screen and (max-width: 500px) {
        background-image: url(${ICON12});
        background-repeat: no-repeat;
        background-position: right 7%;       
        margin-top: 30px;        
        .slider_div {            
            height: auto;
            .mobile_block {
                display: block;
                flex-direction: column;
                .text_div {
                    width: 350px;
                    h2 {
                        width: 100%;
                        font-weight: 700;
                        font-size: 20px;
                    }
                    .paragraphs, .buttons {
                        display: none;
                    }
                }
                .license {
                    /* margin-top: 60px; */
                    margin-right: 0;
                    img {
                        width: 384px;
                        height: 573px;
                    }
                }
                .sm-block-buttons {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 25%;
                    button, a {
                        padding: 10px 15px;
                    }   
                }
            }
            
        }
        .swiper-button-next {
            right: 30%;
            top: 89%;
        }
        .swiper-button-prev {
            left: 30%;
            top: 89%;
        }
    }

    @media only screen and (max-width: 350px) {
        button, a {
            padding: 0;
            font-size: 12px;
        }
    }
`