import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import App from "./App";
import { ContextProvider } from "./context";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Anketa from "./Anketa";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ContextProvider>
    <React.StrictMode>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/anketa" element={<Anketa />} />
        </Routes>
      </BrowserRouter>
    </React.StrictMode>
  </ContextProvider>
);