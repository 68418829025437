import styled from 'styled-components';
import { REMOVEBG_FEEDBACK } from '../../assets/images_path';

export const FeedbackWrapper = styled.div`
    background: #F6F8F9;
    h2 {
        font-size: 36px;
        line-height: 130%;
        width: 500px;
    }
           
    @media only screen and (max-width: 800px) {
        background-image: url(${REMOVEBG_FEEDBACK});
        background-repeat: no-repeat;
        background-position: right 0;       
        .feedback {
            display: block;
            padding: 30px 0;
            h2 {
                width: 80%;
                font-weight: 700;
                font-size: 20px;
                margin-bottom: 20px;
            }
        }
    }  
    
    @media only screen and (max-width: 500px) {
        background-image: url(${REMOVEBG_FEEDBACK});
        background-repeat: no-repeat;
        background-position: right 0;       
        .feedback {
            display: block;
            padding: 30px 0;
            h2 {
                width: 80%;
                font-weight: 700;
                font-size: 20px;
                margin-bottom: 20px;
            }
        }
    }          
`