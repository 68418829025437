import React from "react";
import Catalog from "../catalog/Catalog";
import { MoreWrapper } from "./MoreWrapper";

const More = ({ setMoreModal, slider, facultet, name, state }) => {
  return (
    <MoreWrapper>
      <div className="boxs__">
        <Catalog
          setstate={setMoreModal}
          facultet={facultet}
          slider={slider}
          name={name}
          state={state}
        />
      </div>
    </MoreWrapper>
  );
};

export default More;
