import React from 'react'
import { LAPTOP, RECTANGLE, REMOVEBG_ABOUT, TABLET, TRIANGLE } from '../../assets/images_path';
import { AboutWrapper } from './AboutWrapper';

const About = () => {

    return (
        <AboutWrapper>
            <div className="top__div flex" id="about">
                <div className="top__img">
                    <img src={RECTANGLE} alt="" />
                </div>
                <p>Об университете</p>
            </div>
            <div className="about_contain mx-auto">
                <div className="about_over flex justify-around">
                    <div className="lms_platfrom">
                        <h1>Образовательная платформа  Synergy LMS</h1>
                        <h3>В Университете «Синергия» действует LMS ­система,которая объединяет студентов и преподавателей по всему миру.</h3>
                        <h3>Для обучения онлайн нужен только  компьютер или планшет с выходом в Интернет.</h3>
                        <div className="about-education">
                            <h5>Как проходит обучение</h5>
                            <div className="text-list flex">
                                <div className="one__four">
                                    <div className="list flex">
                                        <h1>1.</h1>
                                        <p>После зачисления в Университет вы  получаете логин и пароль от личного  кабинета.</p>
                                    </div>
                                    <div className="list flex">
                                        <h1>2.</h1>
                                        <p>На вашей странице указаны все  дисциплины, которые необходимо  изучить.</p>
                                    </div>
                                    <div className="list flex">
                                        <h1>3.</h1>
                                        <p>Как и обычный студент, вы учитесь по  семестрам и осваиваете теоретический  материал. Каждую тему вы можете  изучать в удобное для вас время. В вашем  распоряжении бесплатная библиотека со  всеми необходимыми материалами.</p>
                                    </div>
                                    <div className="list flex">
                                        <h1>4.</h1>
                                        <p>После изучения каждой темы вам  необходимо пройти тест, сдача которого  подтверждает успешное освоение  пройденного материала.</p>
                                    </div>
                                </div>
                                <div className="fife__seven">
                                    <div className="list flex">
                                        <h1>5.</h1>
                                        <p>В конце семестра вы сдаёте экзамены  и зачёты по изученным предметам.  В личном кабинете также есть  электронная зачётная книжка, с помощью  которой вы сможете отслеживать свою  успеваемость по всем дисциплинам.</p>
                                    </div>
                                    <div className="list flex">
                                        <h1>6.</h1>
                                        <p>Техническая поддержка платформы  работает круглосуточно. Кроме того,  в любое время вы можете задать вопрос  персональному куратору.</p>
                                    </div>
                                    <div className="list flex">
                                        <h1>7.</h1>
                                        <p>Сдав контрольные, тесты и курсовые,  вы приступаете к написанию  выпускной квалификационной  работы. После её успешной защиты  вы получите государственный диплом  с общеевропейским приложением на  английском языке.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="lms_system">
                        <h2>Преимущества Synergy LMS-системы</h2>
                        <ul className="lms_ul">
                            <li>Работает на всех устройствах</li>
                            <li>Личный кабинет для управления  учебным процессом</li>
                            <li>Мультиформатные учебные материалы</li>
                            <li>Видеолекции с преподавателями вуза</li>
                            <li>Семинары и мастер­классы  известных бизнес­спикеров</li>
                            <li>Общение с тьюторами и кураторами</li>
                            <li>Интерактивное тестирование</li>
                        </ul>
                        <div className="imgs flex justify-end flex-col">
                            <div className="tablet flex justify-end">
                                <img className="removebg_about" src={REMOVEBG_ABOUT} alt="" />
                                <img className="tablet_img" src={TABLET} alt="" />
                            </div>
                            <div className="laptop flexs">
                                <img className="triangle" src={TRIANGLE} alt="" />
                                <img className="laptop_img" src={LAPTOP} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AboutWrapper>
    )
}

export default About