import styled from "styled-components";

export const MoreWrapper = styled.div`
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  padding: 150px;
  .boxs__ {
    z-index: 99999;
  }

  @media only screen and (max-width: 1050px) {
    padding: 160px 50px;
    height: 100%;
    .boxs__ {
      height: 100%;
    }
  }

  @media only screen and (max-width: 900px) {
    padding: 150px 50px;
    height: 100%;
    .boxs__ {
      height: 100%;
    }
  }

  @media only screen and (max-width: 800px) {
    padding: 100px 50px;
    height: 100%;
    .boxs__ {
      height: 100%;
    }
  }

  @media only screen and (max-width: 500px) {
    padding: 50px 10px;
    padding-top: 180px;
    height: 100%;
    .boxs__ {
      height: 100%;
    }
  }
`;
