import React from "react";
import Form from "../Form/Form";
import { BlueFormWrapper } from "./BlueFormWrapper";

const BlueForm = () => {
  return (
    <BlueFormWrapper>
      <div className="container mx-auto">
        <div className="boxs">
          <h2>
            Если Вам нужна помощь в выборе факультета, оставьте заявку на
            консультацию.
          </h2>
          <p>
            Менеджер приемной комиссии поможет подобрать программу и ответит на
            все волнующие Вас вопросы.
          </p>
        </div>
        <Form />
      </div>
    </BlueFormWrapper>
  );
};

export default BlueForm;
