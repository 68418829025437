import React from 'react'
import Form from '../Form/Form';
import { FeedbackWrapper } from './FeedbackWrapper';

const Feedback = () => {
    return (
        <FeedbackWrapper>
            <div className="container mx-auto ">
                <div className="py-14 feedback flex justify-between">
                    <h2>Заполните форму обратной связи, если у вас остались вопросы</h2>
                    <Form />
                </div>
            </div>
        </FeedbackWrapper>
    )
}

export default Feedback