import styled from "styled-components";

export const HeaderWrapper = styled.div`
  header {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100vw;
    background-color: #fff;
    padding: 22px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0px 11px 28px -3px rgba(34, 60, 80, 0.25);
    -moz-box-shadow: 0px 11px 28px -3px rgba(34, 60, 80, 0.25);
    box-shadow: 0px 11px 28px -3px rgba(34, 60, 80, 0.25);
    .content-bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .logo {
        width: 220px;
        object-fit: cover;
        object-position: center;
      }
      ul {
        display: flex;
        align-items: center;
        li {
          cursor: pointer;
          font-family: sans-serif;
          font-weight: 400;
          font-size: 18px;
          line-height: 120%;
          color: #000000;
          margin-right: 40px;
          &:last-child {
            margin-right: 0;
            a {
              display: flex;
              align-items: center;
              img {
                width: 18px;
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
    .content-top {
      display: none;
      align-items: center;
      justify-content: space-between;
      .menu_click {
        width: 35px;
        height: 35px;
        color: #000000;
        font-size: 25px;
        font-weight: 600;
        transition: all 0.4s linear;
        position: relative;
        display: block;
        transform: scale(1.2);
      }
      .menu_click_0 {
        p {
          position: absolute;
          left: 12px;
          &:nth-child(1) {
            top: -25%;
            transition: all 0.4s linear;
            transform: rotate(90deg);
          }
          &:nth-child(2) {
            top: 0%;
            transition: all 0.4s linear;
            transform: rotate(90deg);
          }
          &:nth-child(3) {
            top: 25%;
            transition: all 0.4s linear;
            transform: rotate(90deg);
          }
        }
      }
      .menu_click_1 {
        p {
          position: absolute;
          left: 12px;
          &:nth-child(1) {
            top: 0;
            transform: rotate(45deg);
            transition: all 0.4s linear;
          }
          &:nth-child(2) {
            opacity: 0;
            transition: all 0.4s linear;
            transform: rotate(90deg);
          }
          &:nth-child(3) {
            top: -1px;
            transform: rotate(135deg);
            transition: all 0.4s linear;
          }
        }
      }
      a {
        width: 35px;
        transform: scale(1.2);
      }
    }
  }
  @media only screen and (max-width: 900px) {
    header {
      .content-bottom {
        justify-content: center;
        .logo {
          display: none;
        }
        ul {
          width: inherit;
          justify-content: space-around;
          li {
            font-size: 18px;
            line-height: 120%;
            margin-right: 0;
            &:last-child {
              display: none;
            }
          }
        }
      }
      .yes {
        margin-top: 25px;
        overflow-y: hidden;
        height: 50px;
        transition: all 0.4s linear;
      }
      .no {
        margin-top: 0;
        overflow-y: hidden;
        height: 0;
        transition: all 0.4s linear;
      }
      .content-top {
        display: flex;
      }
    }
  }
  @media only screen and (max-width: 500px) {
    header {
      padding: 16px 0;
      .content-bottom {
        justify-content: center;
        .logo {
          display: none;
        }
        ul {
          width: inherit;
          justify-content: space-around;
          li {
            font-size: 16px;
          }
        }
      }
      .yes {
        margin-top: 15px;
        height: 30px;
      }
      .content-top {
        .menu_click {
          transform: scale(1);
        }
        .logo {
          width: 130px;
        }
        a {
          width: 35px;
          transform: scale(1);
        }
      }
    }
  }
`;
