import styled from 'styled-components';
import { NUMBER, REMOVEBG } from "../../assets/images_path"

export const OurStudentsWrapper = styled.div`
    background-image: url(${REMOVEBG});
    background-repeat: no-repeat;
    background-position: left 10px;
    margin: 100px 0;
    .remove-bg {
        position: relative;
        left: 0;
        top: 0;
    }
    .text {
        h1 {
            font-weight: 300;
            font-size: 50px;
            line-height: 105.9%;
            color: #031412;
            width: 310px;
        }
        p {
            width: 450px;
            font-weight: 300;
            font-size: 20px;
            line-height: 124.9%;
            color: #031412;
            margin-top: 20px;
        }
    }
    .number {
        justify-content: end;
        h3 {
            background: linear-gradient(92.18deg, #E4322B 20.13%, #00249E 92.93%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            font-weight: 200;
            font-size: 50px;
            line-height: 96.4%;
            display: flex;
            justify-content: end;
        }
        .number-bg {
            width: 660px;
            font-size: 200px;
            font-weight: 600;
            line-height: 96.4%;
            color: rgba(0, 0, 0, 0.3);
            background-image: url(${NUMBER});
            background-repeat: repeat;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;         
            display: flex;
            justify-content: end;   
        }
    }

    @media only screen and (max-width: 1280px) {        
        background-position: left 10px;
        height: 200px;
        margin: 30px 0;
        .text {
            p {
                width: 380px;
                font-size: 16px;
            }
            h1 {
                width: auto;
                font-size: 26px;
                line-height: 105.9%;
            }
        }
        .number {
            align-items: flex-end;
            h3 {
                font-weight: 400;
                font-size: 20px;
                line-height: 96.4%;
            }
            .number-bg {
                width: 100%;
                font-weight: 400;
                font-size: 100px;
                line-height: 96.4%;
            }
        }
        
    }

    @media only screen and (max-width: 1000px) {
        background-image: none;
        height: auto;
        margin: 30px 0;        
        .text {
            p {
                display: none;
            }
            h1 {
                width: auto;
                font-weight: bold;
                font-size: 25px;
                line-height: 105.9%;
            }
        }
        .number {
            align-items: flex-end;
            h3 {
                font-weight: 400;
                font-size: 18px;
                line-height: 96.4%;
            }
            .number-bg {
                width: 100%;
                font-weight: 400;
                font-size: 90px;
                line-height: 96.4%;
            }
        }
        
    }

    @media only screen and (max-width: 700px) {
            background-image: none;
            margin: 30px 0;
            .text {
                p {
                display: none;
                }
                h1 {
                    width: auto;
                    font-weight: bold;
                    font-size: 25px;
                    line-height: 105.9%;
                }
            }
            .number {
                align-items: flex-end;
                h3 {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 96.4%;
                }
                .number-bg {
                    width: auto;
                    font-weight: 400;
                    font-size: 60px;
                    line-height: 96.4%;
                }
            }
            
    }

    @media only screen and (max-width: 500px) {
        background-image: none;
        margin: 30px 0;
        .text {
            p {
            display: none;
            }
            h1 {
                width: 100px;
                font-weight: bold;
                font-size: 25px;
                line-height: 105.9%;
            }
        }
        .number {
            align-items: flex-end;
            width: 180px;
            h3 {
                font-weight: 400;
                font-size: 16px;
                line-height: 96.4%;
            }
            .number-bg {
                width: 160px;
                font-weight: 400;
                font-size: 50px;
                line-height: 96.4%;
            }
        }        
    }

    @media only screen and (max-width: 320px) {
        .text {
            h1 {
                font-size: 20px;
                
            }
        }
        .number {
            align-items: flex-end;
            width: 150px;
            h3 {
                font-weight: 400;
                font-size: 14px;
                line-height: 96.4%;
            }
            .number-bg {
                width: 140px;
                font-weight: 400;
                font-size: 44px;
                line-height: 96.4%;
            }
        }        
    }
`