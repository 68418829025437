import styled from "styled-components";

export const VideoWrapper = styled.div`
  margin: 50px 0 120px 0;
  .box {
    display: flex;
    align-items: center;
    h1 {
      font-family: "Raleway", sans-serif;
      margin-right: 50px;
      font-weight: 700;
      font-size: 46px;
      line-height: 140%;
      color: #263238;
    }
    p {
      font-family: "Raleway", sans-serif;
      font-weight: 500;
      font-size: 18px;
      line-height: 140%;
      color: #6f8793;
    }
  }
  iframe {
    margin-top: 30px;
    width: 100%;
    height: 720px;
    border-radius: 2px;
  }
  @media only screen and (max-width: 1050px) {
    margin: 40px 0 80px 0;
    .box {
      display: block;
      h1 {
        font-size: 46px;
      }
      p {
        display: none;
      }
    }
    iframe {
      margin-top: 30px;
      width: 100%;
      height: 500px;
      border-radius: 2px;
    }
  }
  @media only screen and (max-width: 700px) {
    margin: 30px 0 60px 0;
    .box {
      display: block;
      h1 {
        font-size: 36px;
      }
    }
    iframe {
      margin-top: 20px;
      height: 350px;
    }
  }
  @media only screen and (max-width: 700px) {
    margin: 30px 0 60px 0;
    .box {
      display: block;
      h1 {
        font-size: 25px;
      }
    }
    iframe {
      margin-top: 15px;
      height: 200px;
    }
  }
`;
