import styled from "styled-components";

export const FormWrapper = styled.div`
  max-width: 1000px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  padding: 40px 60px;
  position: relative;

  .plus {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    p {
      font-family: "Raleway", sans-serif;
      font-weight: 100;
      cursor: pointer;
      display: inline-block;
      font-size: 40px;
      transform: scale(3) rotate(45deg);
    }
  }

  input {
    padding: 8px 0;
    display: block;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.4);
    margin-top: 25px;
    outline: none;

    font-family: sans-serif;
    font-weight: 400;
    font-size: 17px;
    line-height: 140%;
    &:first-child {
      margin-top: 0;
    }
  }
  .flex {
    margin-top: 25px;
    .input {
      width: min-content;
      transform: scale(1.8);
      margin-left: 5px;
      margin-right: 20px;
    }
    label {
      font-family: "Raleway", sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 120%;
      color: rgba(0, 0, 0, 0.4);
    }
  }
  .button {
    margin-top: 30px;
    background: #00249e;
    border-radius: 6px;
    width: 100%;
    padding: 20px;
    display: block;

    font-family: "Raleway", sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    color: #ffffff;
    text-align: center;
  }
  .boxs {
    position: absolute;
    background-color: #ffffff;
    z-index: 5;
    width: 99%;
    top: 85px;
    left: 0;
    display: block;
    height: calc(100% - 100px);
    padding: 40px 60px;
    img {
      margin: 0 auto;
      display: block;
    }
    h3 {
      margin: 30px 0 30px 0;
      font-weight: 700;
      font-size: 24px;
      line-height: 130%;
      color: #000000;
      text-align: center;
    }
    p {
      font-weight: 400;
      font-size: 17px;
      line-height: 140%;
      color: rgba(0, 0, 0, 0.4) !important;
      text-align: center;
    }
    a {
      display: block;
      text-align: center;
      text-transform: uppercase;
    }
  }
  .boxs2 {
    top: 10px;
    height: calc(100% - 10px);
  }
  .grid_box {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    a {
      background-color: #e4322b;
    }
  }
  @media only screen and (max-width: 1050px) {
    max-width: 700px;
    margin: auto;
    padding: 40px 60px;
  }
  @media only screen and (max-width: 700px) {
    max-width: 700px;
    margin: auto;
    padding: 20px 30px;
    .boxs {
      width: 99% !important;
      top: 65px;
      height: calc(100% - 80px);
      padding: 20px 30px;
      h3 {
        font-size: 24px;
      }
      p {
        font-size: 17px;
        line-height: 140%;
      }
    }
    .boxs2 {
      top: 10px;
      height: calc(100% - 10px);
    }
    .grid_box {
      grid-template-columns: 1fr;
    }
  }
  @media only screen and (max-width: 500px) {
    input {
      padding: 8px 0;
      margin-top: 20px;
      font-size: 17px;
      &:first-child {
        margin-top: 0;
      }
    }
    .button {
      margin-top: 25px;
      padding: 12px;
      font-size: 16px;
    }
    .flex {
      margin-top: 20px;
      .input {
        width: min-content;
        transform: scale(1.5);
        margin-left: 5px;
        margin-right: 15px;
      }
      label {
        font-size: 12px;
      }
    }
    .boxs {
      padding: 15px 20px;
      img {
        transform: scale(0.7);
      }
      h3 {
        font-size: 20px;
      }
      p {
        font-size: 14px;
      }
    }
    .boxs2 {
      top: 10px;
      height: calc(100% - 10px);
    }
  }
`;
